import * as React from 'react';
import * as Constants from '../../constants';
import { connect } from 'react-redux';
import * as Utils from '../../utils';
import { SetPageAction } from '../actions';

interface IProps {
	SetPage?: Function;
}

class AboutContainer extends React.Component<IProps, any> {
	constructor(props: IProps) {
		super(props);
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
	}

	private onSignupClick() {
		this.props.SetPage(Constants.SignUpPage, new Map<string, string>());
	}

	public render(): React.ReactNode {
		const headingText: string = 'lg:text-5xl md:text-3xl text-2xl my-4';
		const subheadingText: string = 'text-base m-2';
		const imageClasses: string = 'm-auto object-cover md:max-w-md ';
		const infoCard: string = 'my-10';
		return (
			<div className="about text-center text-2xl mb-24">
				<div className="text-center mb-12">
					<p className={headingText}>Monitor your service availability</p>
					<p className={headingText}>Provide excellent service to your users!</p>
					<div className="subheadindContainer">
						<p className={subheadingText}>High availability = High user retention</p>
						<p className={subheadingText}>
							Automated monitoring of websites and APIs will help you provide high value to your users.
						</p>
					</div>
					<div className="w-1/2 mx-auto text-base mb-16 mt-8">
						<button
							type="submit"
							className="btn btn-blue-outline my-2 w-full"
							onClick={this.onSignupClick.bind(this)}
						>
							Create Account
						</button>
					</div>
				</div>
				<div className={infoCard}>
					<p>How fast your website is your API?</p>
					<img className={imageClasses} src={Utils.getAssetUrl('about/graph-2.png')} />
				</div>
				<div className={infoCard}>
					<p>Inspect the response codes</p>
					<div>
						<img className={imageClasses} src={Utils.getAssetUrl('about/table.png')} />
					</div>
				</div>
				<div className={infoCard}>
					<p>Manage multiple URLs</p>
					<img className={imageClasses} src={Utils.getAssetUrl('about/urls.png')} />
				</div>
				<div className={infoCard}>
					<p>Live services can have downtime</p>
					<p>Find out if your service is down before your users do</p>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any, ownProps: IProps): IProps => {
	return {};
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
	return {
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutContainer);
