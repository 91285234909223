import * as actions from '../../actions/actionTypes';
import * as Constants from '../../../constants';
import ITrendUrl from '../interfaces/ITrendUrlState';

const initialState: ITrendUrl = {
	trendUrlDataLoaded: Constants.NOT_LOADED,
	trendUrl: '',
	trend: null,
};

const ITrendUrl = (state: ITrendUrl = initialState, action: any) => {
	switch (action.type) {
		case actions.RESET_STATE: {
			return initialState;
		}

		case actions.SET_TREND_URL: {
			const newState: ITrendUrl = Object.assign({}, state);
			newState.trendUrl = action.data;
			return newState;
		}

		case actions.SET_URL_TREND_DATA_LOADED: {
			const newState: ITrendUrl = Object.assign({}, state);
			newState.trendUrlDataLoaded = action.data;
			return newState;
		}
		case actions.SET_TREND_URL_DATA: {
			const newState: ITrendUrl = Object.assign({}, state);
			const surl: string = <string>action.url;
			if (newState.trend === null) {
				newState.trend = new Map();
			}
			newState.trend.set(surl, action.data);
			newState.trendUrl = action.url;
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default ITrendUrl;
