export const UPDATE_PROFILE_URL_DATA: string = 'UPDATE_PROFILE_URL_DATA';
export const UPDATE_URL_DATA: string = 'UPDATE_URL_DATA';
export const UPDATE_URL: string = 'UPDATE_URL';
export const SET_PROFILE_URL_DATA_LOADED: string = 'PROFILE_URL_DATA_LOADED';
export const SET_USER_TRACKED_URL_DATA: string = 'SET_USER_TRACKED_URL_DATA';
export const SET_OVERVIEW_DATA: string = 'SET_OVERVIEW_DATA';
export const SET_PAGE: string = 'SET_PAGE';
export const SET_URL_TREND_DATA_LOADED: string = 'SET_URL_TREND_DATA_LOADED';
export const SET_TREND_URL: string = 'SET_TREND_URL';
export const SET_TREND_URL_DATA: string = 'SET_TREND_URL_DATA';
export const SET_LOGIN_LOADED: string = 'SET_LOGIN_LOADED';
export const SET_REQUEST_INVITE_LOADED: string = 'SET_REQUEST_INVITE_LOADED';
export const SET_REGISTER_USER_LOADED: string = 'SET_REGISTER_USER_LOADED';
export const SET_FULL_USER_STATE: string = 'SET_FULL_USER_STATE';
export const ADD_URL: string = 'ADD_URL';
export const SHOW_ADD_URL_WINDOW: string = 'SHOW_ADD_URL_WINDOW';
export const REMOVE_URL: string = 'REMOVE_URL';
export const SET_GLOBAL_MESSAGE: string = 'SET_GLOBAL_MESSAGE';
export const SET_INIT_COMPLETE: string = 'SET_INIT_COMPLETE';
export const SET_REFRESHING_TOKEN: string = 'SET_REFRESHING_TOKEN';
export const RESET_STATE: string = 'RESET_STATE';
export const SET_ADD_URL_LOADED: string = 'SET_ADD_URL_LOADED';
export const SET_REMOVE_URL_LOADED: string = 'SET_REMOVE_URL_LOADED';
export const SET_CHANGE_PASSWORD_LOADED: string = 'SET_CHANGE_PASSWORD_LOADED';
export const SET_CHANGE_PASSWORD_ERROR_MESSAGE: string = 'SET_CHANGE_PASSWORD_ERROR_MESSAGE';
export const SET_CHANGE_PASSWORD_SUCCESS_MESSAGE: string = 'SET_CHANGE_PASSWORD_SUCCESS_MESSAGE';
export const SET_TOKEN: string = 'SET_TOKEN';
export const SET_LOGIN_ERROR_CODE: string = 'SET_LOGIN_ERROR_CODE';
export const SET_REQUEST_PASSWORD_LOADED: string = 'SET_REQUEST_PASSWORD_LOADED';
export const SET_VALIDATE_RESET_PASSWORD_TOKEN_LOADED: string = 'SET_VALIDATE_RESET_PASSWORD_TOKEN_LOADED';
export const SET_REQUEST_PASSWORD_FORM_TOKEN_VALIDITY: string = 'SET_REQUEST_PASSWORD_FORM_TOKEN_VALIDITY';
export const SET_VALIDATE_RESET_PASSWORD_TOKEN_ERROR_CODE: string = 'SET_VALIDATE_RESET_PASSWORD_TOKEN_ERROR_CODE';
export const SET_RESET_PASSWORD_RESET_ERROR_CODE: string = 'SET_RESET_PASSWORD_RESET_ERROR_CODE';
export const SET_REQUEST_PASSWORD_REQUEST_LOADED: string = 'SET_REQUEST_PASSWORD_REQUEST_LOADED';
