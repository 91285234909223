import * as React from 'react';
import * as Constants from '../../constants';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

interface IPasswordOwnState {
	visible: boolean;
}
interface IPasswordOwnProps {
	text: string;
	onKeyUp: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	setFromDom?: (val: string) => void;
	fieldRef?: React.RefObject<HTMLInputElement>;
	placeholder?: string;
}

//class renders the home page for user
export default class Password extends React.Component<IPasswordOwnProps, IPasswordOwnState> {
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: IPasswordOwnProps) {
		super(props);
		this.state = {
			visible: false,
		};
		this.passwordField = React.createRef();
	}
	public componentDidMount() {
		if (this.props.setFromDom !== undefined) {
			var refField: React.RefObject<HTMLInputElement>;
			refField = this.props.fieldRef !== undefined ? this.props.fieldRef : this.passwordField;
			if (refField !== undefined && refField.current !== undefined) {
				console.log('componentDidMount - ' + refField.current.value);
				this.props.setFromDom(refField.current.value);
			}
		}
	}

	private toggleVisibility() {
		this.setState({ visible: !this.state.visible });
	}

	public render(): React.ReactNode {
		return (
			<div>
				<div className="relative w-full">
					<div className="absolute inset-y-0 right-0 flex items-center px-2">
						<label
							onClick={this.toggleVisibility.bind(this)}
							className="bg-gray-300 hover:bg-gray-400 rounded px-2 py-1 text-sm text-gray-600 font-mono cursor-pointer"
						>
							{this.state.visible ? 'hide' : 'show'}
						</label>
					</div>
					<input
						className="textbox-outline-blue my-2 w-full"
						type={this.state.visible ? 'text' : 'password'}
						placeholder={this.props.placeholder !== undefined ? this.props.placeholder : 'Password'}
						onKeyUp={this.props.onKeyUp}
						ref={this.props.fieldRef !== undefined ? this.props.fieldRef : this.passwordField}
					/>
				</div>
			</div>
		);
	}
}
