import * as React from 'react';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import { ResetPasswordAction, SetPageAction, SetRequestPasswordLoaded } from '../actions';
import ICombinedState from '../reducers/interfaces/ICombinedState';

interface IResetPasswordComponentStateProps {
	requestPasswordLoading: string;
}
interface IResetPasswordComponentOwnProps {}
interface IResetPasswordComponentDispatchProps {
	ResetPassword: Function;
	SetPage: Function;
	SetRequestPasswordLoadedState: Function;
}

type IResetPasswordComponentProps = IResetPasswordComponentOwnProps &
	IResetPasswordComponentDispatchProps &
	IResetPasswordComponentStateProps;

interface IResetPasswordComponentState {
	email: string;
	errorMessage: string;
}

class ResetPasswordComponent extends React.Component<IResetPasswordComponentProps, IResetPasswordComponentState> {
	private emailField: React.RefObject<HTMLInputElement>;
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: IResetPasswordComponentProps) {
		super(props);
		this.state = {
			email: '',
			errorMessage: '',
		};
		this.emailField = React.createRef();
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
		//reset the request state to allow resetting
		this.props.SetRequestPasswordLoadedState(Constants.NOT_LOADED);
	}

	private resetPassword() {
		if (this.props.requestPasswordLoading === Constants.LOADING) {
			return;
		}
		let email: string = this.state.email;
		if (email === '') {
			//check DOM
			email = this.emailField.current.value;
		}
		if (!Utils.isValidEmail(email)) {
			this.setState({ errorMessage: 'Email not valid' });
			return;
		}
		this.props.ResetPassword(email);
	}

	private showLogin() {
		this.props.SetPage(Constants.LoginPage, new Map<string, string>());
	}

	//generic
	private editText(val: string, e: Event) {
		let change: any = {};
		change[val] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign(this.state, change, { loginEmailError: '' }));
	}

	//checkbox
	private onCheck(e: Event) {
		let subscribe: boolean = (e.currentTarget as HTMLInputElement).checked;
		this.setState(Object.assign(this.state, { subscribe: subscribe }));
	}

	private getErrorMessage() {
		if (this.state.errorMessage !== '') {
			return this.state.errorMessage;
		}

		if (this.props.requestPasswordLoading === Constants.FAILED_LOADED) {
			return 'There was an error requesting Password.';
		}
		return '';
	}

	public render(): React.ReactNode {
		return (
			<div>
				{this.props.requestPasswordLoading === Constants.LOADED ? (
					<p className="text-center">
						Instructions to reset the password have been sent over email. If you have not recieved it,
						please check your spam folder.
					</p>
				) : (
					<form
						className="p-5 w-full md:w-1/2 mx-auto"
						onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
							e.preventDefault();
							this.resetPassword();
						}}
					>
						<p className="mx-auto">Enter your email to reset password</p>
						<input
							className="textbox-outline-blue my-2 w-full"
							onKeyUp={this.editText.bind(this, 'email')}
							type="email"
							name="email"
							placeholder="Email"
							ref={this.emailField}
						/>
						<p className="text-red-500 text-xs italic -my-2">{this.getErrorMessage()}&nbsp;</p>
						<div>
							<button
								type="submit"
								className="btn btn-blue-outline my-2 w-full"
								disabled={this.props.requestPasswordLoading === Constants.LOADING}
							>
								Reset Password
							</button>
						</div>
					</form>
				)}
				<div className="w-full text-center my-2">
					<div className="">
						<button onClick={this.showLogin.bind(this)} className="btn btn-blue-link">
							{this.props.requestPasswordLoading === Constants.LOADED ? 'Done' : 'Cancel'}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (
	state: ICombinedState,
	ownProps: IResetPasswordComponentStateProps,
): IResetPasswordComponentStateProps => {
	return {
		requestPasswordLoading: state.ui.requestPasswordLoading,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: IResetPasswordComponentDispatchProps) => {
	return {
		ResetPassword: (email: string) => {
			dispatch(ResetPasswordAction(email));
		},
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		SetRequestPasswordLoadedState: (data: string) => {
			dispatch(SetRequestPasswordLoaded(data));
		},
	};
};

export default connect<IResetPasswordComponentStateProps, IResetPasswordComponentDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ResetPasswordComponent);
