//todo rename as aut or main or whatever

import * as React from 'react';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import ICombinedState from '../reducers/interfaces/ICombinedState';
import SignUpComponent from './SignUpComponent';
import LoginComponent from './LoginComponent';
import ResetPasswordComponent from './ResetPasswordComponent';
import ResetPasswordFormComponent from './ResetPasswordFormComponent';

interface ILoginContainerStateProps {
	loginErrorCode: number;
	loginLoading: string;
}
const LOGIN_MODE = 'LOGIN_MODE';
const SIGNUP_MODE = 'SIGNUP_MODE';
interface ILoginContainerOwnProps {
	mode: string;
}
interface ILoginContainerDispatchProps {}

type ILoginContainerProps = ILoginContainerOwnProps & ILoginContainerDispatchProps & ILoginContainerStateProps;

interface ILoginContainerState {
	mode: string;
}

class LoginContainer extends React.Component<ILoginContainerProps, ILoginContainerState> {
	private emailField: React.RefObject<HTMLInputElement>;
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: ILoginContainerProps) {
		super(props);
		this.state = {
			mode: LOGIN_MODE,
		};
		this.emailField = React.createRef();
		this.passwordField = React.createRef();
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
		console.log(this.state.mode);
	}

	public setMode(m: string) {
		this.setState({ mode: m });
	}

	public render(): React.ReactNode {
		return (
			<div>
				<div className="flex flex-col md:flex-row">
					<div className="p-5 w-full">
						{this.props.mode === Constants.LOGIN_MODE ? (
							<LoginComponent />
						) : this.props.mode === Constants.RESET_PASSWORD_MODE ? (
							<ResetPasswordComponent />
						) : this.props.mode === Constants.RESET_PASSWORD_FORM_MODE ? (
							<ResetPasswordFormComponent />
						) : (
							<SignUpComponent />
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: ICombinedState, ownProps: ILoginContainerStateProps): ILoginContainerStateProps => {
	return {
		loginErrorCode: state.ui.loginErrorCode,
		loginLoading: state.ui.loginLoading,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: ILoginContainerDispatchProps) => {
	return {};
};

export default connect<ILoginContainerStateProps, ILoginContainerDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(LoginContainer);
