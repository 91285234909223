import * as actions from '../../actions/actionTypes';
import * as Constants from '../../../constants';
import IMainState from '../interfaces/IMainState';
import IOverviewData from '../../../interfaces/IOverviewData';

const initialState: IMainState = {
	url: 'https://www.logic-square.com',
	//urlData: Constants.SAMPLE,
	urlData: [],
	profileData: {
		AvgTotaltime: 0,
		AvgTtfb: 0,
		R1XX: 0,
		R2XX: 0,
		R3XX: 0,
		R4XX: 0,
		R5XX: 0,
		dataPoints: 0,
	},
	profileDataLoaded: Constants.NOT_LOADED,
	userTrackedUrlData: [],
	userTrackedUrls: [],
	overviewData: [],
	overviewDataTime: 0,
	planData: [
		{
			name: 'Free',
			planId: 'free_trial',
			dimentions: [
					{ id: 'url_count', planId: 'free_trial', limit: 10, limitComparatorType: 'lte' },
					{ id: 'url_refresh_frequency', planId: 'free_trial', limit: 86400, limitComparatorType: 'gte' },
				],
		},
	],
};

const main = (state: IMainState = initialState, action: any) => {
	switch (action.type) {
		case actions.RESET_STATE: {
			return initialState;
		}
		case actions.UPDATE_URL_DATA: {
			const newState: IMainState = Object.assign({}, state);
			newState.urlData = action.data;
			return newState;
		}
		case actions.UPDATE_URL: {
			const newState: IMainState = Object.assign({}, state);
			newState.url = action.data;
			return newState;
		}
		case actions.UPDATE_PROFILE_URL_DATA: {
			const newState: IMainState = Object.assign({}, state);
			newState.profileData = action.data;
			newState.profileDataLoaded = Constants.LOADED;
			return newState;
		}
		case actions.SET_PROFILE_URL_DATA_LOADED: {
			const newState: IMainState = Object.assign({}, state);
			newState.profileDataLoaded = action.data;
			return newState;
		}
		case actions.SET_USER_TRACKED_URL_DATA: {
			const newState: IMainState = Object.assign({}, state);
			newState.userTrackedUrlData = action.data;
			return newState;
		}
		case actions.SET_OVERVIEW_DATA: {
			const newState: IMainState = Object.assign({}, state);
			newState.overviewData = action.data;
			newState.overviewDataTime = Date.now();
			return newState;
		}
		case actions.SET_ADD_URL_LOADED: {
			const newState: IMainState = Object.assign({}, state);
			//newState.overviewData = action.data;
			if (action.data === Constants.LOADED) {
				const overviewData: IOverviewData[] = newState.overviewData.splice(0);
				const newEntry: IOverviewData = {
					Indicator: -1, //new
					Ssl: '',
					AvgResponse: 0,
					Frequency: 60 * 60,
					Url: action.url,
				};
				overviewData.push(newEntry);
				newState.overviewData = overviewData;
			}
			return newState;
		}
		case actions.SET_REMOVE_URL_LOADED: {
			const newState: IMainState = Object.assign({}, state);
			//newState.overviewData = action.data;
			if (action.data == Constants.LOADED) {
				let overviewData: IOverviewData[] = newState.overviewData.splice(0);
				let idx: number = 0;
				for (let i: number = overviewData.length - 1; i >= 0; i--) {
					if (overviewData[i].Url == action.url) {
						idx = i;
						break;
					}
				}
				//remove 1 item at index
				overviewData.splice(idx, 1);
				newState.overviewData = overviewData;
			}
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default main;
