import * as React from 'react';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import { LoginAction, SetPageAction } from '../actions';
import ICombinedState from '../reducers/interfaces/ICombinedState';
import Password from '../components/Password';

interface ILoginComponentStateProps {
	loginErrorCode: number;
	loginLoading: string;
}
interface ILoginComponentOwnProps {}
interface ILoginComponentDispatchProps {
	Login: Function;
	SetPage: Function;
}

type ILoginComponentProps = ILoginComponentOwnProps & ILoginComponentDispatchProps & ILoginComponentStateProps;

interface ILoginComponentState {
	email: string;
	password: string;
	name: string;
	subscribe: boolean;
	loginEmailError: string;
}

class LoginComponent extends React.Component<ILoginComponentProps, ILoginComponentState> {
	private emailField: React.RefObject<HTMLInputElement>;
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: ILoginComponentProps) {
		super(props);
		this.state = {
			email: '',
			password: '',
			name: '',
			subscribe: true,
			loginEmailError: '',
		};
		this.emailField = React.createRef();
		this.passwordField = React.createRef();
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
	}

	private login() {
		if (this.props.loginLoading === Constants.LOADING) {
			return;
		}
		let email: string = this.state.email;
		if (email === '') {
			//check DOM
			email = this.emailField.current.value;
		}
		if (!Utils.isValidEmail(email)) {
			this.setState({ loginEmailError: 'Email not valid' });
			return;
		}

		let password: string = this.state.password;
		if (password === '') {
			//check DOM
			password = this.passwordField.current.value;
		}
		if (email == '' || password == '') {
			this.setState({ loginEmailError: 'Email or password is invalid' });
			return;
		}
		this.props.Login(email, password);
	}

	private showResetPassword() {
		console.log('forgot login');
		this.props.SetPage(Constants.ResetPasswordPage, new Map<string, string>());
	}

	private showSignUp() {
		console.log('forgot login');
		this.props.SetPage(Constants.SignUpPage, new Map<string, string>());
	}

	//generic
	private editText(val: string, e: Event) {
		let change: any = {};
		change[val] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign(this.state, change, { loginEmailError: '' }));
	}

	//checkbox
	private onCheck(e: Event) {
		let subscribe: boolean = (e.currentTarget as HTMLInputElement).checked;
		this.setState(Object.assign(this.state, { subscribe: subscribe }));
	}

	public render(): React.ReactNode {
		return (
			<div>
				<form
					className="p-5 w-full md:w-1/2 mx-auto"
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
						e.preventDefault();
						this.login();
					}}
				>
					<p className="mx-auto">Sign In</p>
					<input
						className="textbox-outline-blue my-2 w-full"
						onKeyUp={this.editText.bind(this, 'email')}
						type="email"
						name="email"
						placeholder="Email"
						ref={this.emailField}
					/>
					<p className="text-red-500 text-xs italic -my-2">{this.state.loginEmailError}&nbsp;</p>
					<Password
						text={this.state.password}
						onKeyUp={this.editText.bind(this, 'password')}
						fieldRef={this.passwordField}
					/>
					<p className="text-red-500 text-xs italic -my-2">
						{this.props.loginErrorCode !== Constants.ERROR_CODE_NO_ERROR
							? 'There was an error logging in.'
							: null}
						&nbsp;
					</p>
					<div>
						<button
							type="submit"
							className="btn btn-blue-outline my-2 w-full"
							disabled={this.props.loginLoading === Constants.LOADING}
							onClick={this.login.bind(this)}
						>
							Log In
						</button>
					</div>
				</form>
				<div className="w-full text-center my-2">
					<div className="">
						<button onClick={this.showResetPassword.bind(this)} className="btn btn-blue-link">
							Forgot Password?
						</button>
					</div>
					<div className="">
						No account?{' '}
						<button onClick={this.showSignUp.bind(this)} className="btn btn-blue-link">
							Create one
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: ICombinedState, ownProps: ILoginComponentStateProps): ILoginComponentStateProps => {
	return {
		loginErrorCode: state.ui.loginErrorCode,
		loginLoading: state.ui.loginLoading,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: ILoginComponentDispatchProps) => {
	return {
		Login: (email: string, password: string) => {
			dispatch(LoginAction(email, password));
		},
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
	};
};

export default connect<ILoginComponentStateProps, ILoginComponentDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(LoginComponent);
