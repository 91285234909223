import * as actions from '../../actions/actionTypes';
import * as Constants from '../../../constants';

import IUserState from '../interfaces/IUserState';

const initialState: IUserState = {
	email: '',
	name: '',
	planId: '',
	token: '',
	tokenExpiry: 0,
};

const ui = (state: IUserState = initialState, action: any) => {
	switch (action.type) {
		case actions.RESET_STATE: {
			return initialState;
		}

		case actions.SET_FULL_USER_STATE: {
			const newState: IUserState = Object.assign({}, state);
			newState.email = action.email;
			newState.name = action.name;
			newState.planId = action.planId;
			if (newState.planId == '' || newState.planId == undefined) {
				newState.planId = 'free_trial';
			}
			newState.token = action.token;
			newState.tokenExpiry = action.tokenExpiry;
			return newState;
		}

		case actions.SET_TOKEN: {
			const newState: IUserState = Object.assign({}, state);
			newState.token = action.token;
			newState.tokenExpiry = action.tokenExpiry;
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default ui;
