import * as React from 'react';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IOverviewData from '../../interfaces/IOverviewData';
import OverviewRow from './OverviewRow';
import UpgradePlanPopup from '../components/UpgradePlanPopup';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

//import * as styles from './Overview.css';

interface IAddUrlOwnState {
	url: string;
	status: string;
	showUpgradePlanPopup: boolean;
}
interface IAddUrlOwnProps {
	AddUrl: Function;
	ShowAddUrlWindow: Function;
	GetOverviewData: Function;
	urlCount: number;
	urlLimit: number;
}

//class renders the home page for user
export default class AddUrl extends React.Component<IAddUrlOwnProps, IAddUrlOwnState> {
	constructor(props: IAddUrlOwnProps) {
		super(props);
		this.state = { url: '', status: '', showUpgradePlanPopup: false };
	}
	public componentDidMount() {}

	private onTextChanged(key: string, e: Event) {
		const changed: any = {};
		changed[key] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign({}, this.state, changed));
	}

	private toggleUpgradePlanPopup() {
		this.setState({ showUpgradePlanPopup: !this.state.showUpgradePlanPopup });
	}

	private onAdd(e: Event) {
		if (this.props.urlCount < this.props.urlLimit) {
			//perform the add
			this.props.ShowAddUrlWindow(true);
		} else {
			//show upgrade plan window
			this.toggleUpgradePlanPopup();
		}
	}

	private onReload(e: Event) {
		//perform the add
		this.props.GetOverviewData(null);
	}

	public render(): React.ReactNode {
		return (
			<div className="flex flex-row justify-between items-center border-gray-400 border-b h-12">
				<div className="flex flex-row items-center justify-start ">
					<img
						className="logo cursor-pointer"
						src={Utils.getAssetUrl('ligature-symbols_2-11-0_add_256_0_007dff_none.png')}
						onClick={this.onAdd.bind(this)}
					/>
					<p className="cursor-pointer m-4" onClick={this.onAdd.bind(this)}>
						Add ({this.props.urlCount}/{this.props.urlLimit})
					</p>
				</div>

				<img
					className="logo cursor-pointer"
					src={Utils.getAssetUrl('mfg-labs-iconset_2014-07-29_reload_256_0_007dff_none.png')}
					onClick={this.onReload.bind(this)}
				/>
				<UpgradePlanPopup
					isOpen={this.state.showUpgradePlanPopup}
					onClose={this.toggleUpgradePlanPopup.bind(this)}
					message="You have reached the maximum allowed URLs. Upgrade your plan or remove an existing URL to add more."
				/>
			</div>
		);
	}
}
