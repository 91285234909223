import IUrlData from './interfaces/IUrlData';

export const LOADING: string = 'loading';
export const LOADED: string = 'loaded';
export const NOT_LOADED: string = 'not_loaded';
export const FAILED_LOADED: string = 'failed_loaded';

//ui constants
interface UITheme {
	COLOR_BRAND: string;
	COLOR_CONTENT: string;
	APP_BACKGROUND: string;
}
export const UI: UITheme = {
	COLOR_BRAND: '#222',
	COLOR_CONTENT: '#222',
	APP_BACKGROUND: '#fff',
};
export const COLOR_BLACK_CONTENT: string = '#222';
export const LIGHT_BLUE: string = '#77a6f7';
export const ICON_BLUE: string = '007dff';
export const APP_BACKGROUND: string = '#FFF';
export const APP_TEXT: string = '#222';

export const TOKEN_EXPIRY_TTL: number = 28800; //8 hours

export const BASE_URL: string = 'https://vtcnw2ihwf.execute-api.us-west-2.amazonaws.com/test';

export const ASSETS_URL: string = '/assets';

export const RESPONSE_TYPES: any = {
	200: 'OK',
	300: 'REDIRECT',
	400: 'BAD_REQUEST',
	500: 'SERVER_ERROR',
};

export const PERIOD_MONTH_VALUE = 30 * 24 * 60 * 60;
export const PERIOD_WEEK_VALUE = 7 * 24 * 60 * 60;
export const PERIOD_DAY_VALUE = 24 * 60 * 60;
export const PERIOD_HOUR_VALUE = 60 * 60;

export const UrlTrendPage: string = 'UrlTrend';
export const LoginPage: string = 'Login';
export const SignUpPage: string = 'SignUp';
export const ResetPasswordPage: string = 'ResetPassword';
export const ResetPasswordForm: string = 'ResetPasswordForm';
export const Overview: string = 'Overview';
export const AboutPage: string = 'About';
export const SettingsPage: string = 'Settings';
export const LandingPage: string = 'About';
export const PricingPage: string = 'Pricing';

export const LOGIN_MODE = 'LOGIN_MODE';
export const SIGNUP_MODE = 'SIGNUP_MODE';
export const RESET_PASSWORD_MODE = 'RESET_PASSWORD_MODE';
export const RESET_PASSWORD_FORM_MODE = 'RESET_PASSWORD_FORM_MODE';

export const ERROR_CODE_NO_ERROR: number = 0;
export const ERROR_CODE_UNAUTHORIZED: number = 1;
export const ERROR_CODE_INVALID_TOKEN: number = 2;
export const ERROR_CODE_ROUTE_NOT_FOUND: number = 4;
export const ERROR_CODE_NO_URLS_FOUND: number = 5;
export const ERROR_CODE_MISSING_PARAMS: number = 6;
export const ERROR_CODE_BAD_PASSWORD: number = 7;
export const ERROR_CODE_DB_ERROR: number = 8;
export const ERROR_CODE_RELOGIN: number = 9;
export const ERROR_CODE_INVALID_EMAIL: number = 10;
export const ERROR_CODE_BAD_PARAMS: number = 11;
export const ERROR_CODE_USER_EXISTS: number = 12;
export const ERROR_CODE_FORGOT_PASSWORD_INVALID_TOKEN: number = 13;
export const ERROR_CODE_ACCOUNT_LIMIT_EXCEEDED: number = 14;

//client error codes
export const ERROR_CODE_FORGOT_PASSWORD_MISSING_TOKEN: number = 1000001;
export const ERROR_CODE_EXCEPTION: number = 1000002;

export const SAMPLE: IUrlData[] = [
	{
		Url: 'http://google.com',
		Time: 1555513743,
		Dns: 3319078,
		TlsHandshake: 0,
		ConnectTime: 6419333,
		Ttfb: 39613510,
		Totaltime: 39698244,
		ConnectionPool: 9961450,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555514463,
		Dns: 5314227,
		TlsHandshake: 0,
		ConnectTime: 7257725,
		Ttfb: 43196467,
		Totaltime: 43254297,
		ConnectionPool: 25275009,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555690495,
		Dns: 17627544,
		TlsHandshake: 0,
		ConnectTime: 7788370,
		Ttfb: 43619938,
		Totaltime: 43684725,
		ConnectionPool: 25575178,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555690517,
		Dns: 1278454,
		TlsHandshake: 0,
		ConnectTime: 25755234,
		Ttfb: 73564017,
		Totaltime: 73628175,
		ConnectionPool: 27193315,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555690549,
		Dns: 19185242,
		TlsHandshake: 0,
		ConnectTime: 7985932,
		Ttfb: 53042296,
		Totaltime: 53093082,
		ConnectionPool: 27311681,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555860051,
		Dns: 16146948,
		TlsHandshake: 0,
		ConnectTime: 26683287,
		Ttfb: 90764378,
		Totaltime: 90819755,
		ConnectionPool: 42959484,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555860306,
		Dns: 41427242,
		TlsHandshake: 0,
		ConnectTime: 18791782,
		Ttfb: 77630602,
		Totaltime: 77696417,
		ConnectionPool: 60326889,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555860713,
		Dns: 942697,
		TlsHandshake: 0,
		ConnectTime: 8149610,
		Ttfb: 27475587,
		Totaltime: 27526457,
		ConnectionPool: 9208591,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
	{
		Url: 'http://google.com',
		Time: 1555931740,
		Dns: 965591,
		TlsHandshake: 0,
		ConnectTime: 26131038,
		Ttfb: 74958657,
		Totaltime: 75013016,
		ConnectionPool: 27207172,
		StatusCode: 301,
		ContentLength: 219,
		Data: { Location: 'http://www.google.com/' },
	},
];
