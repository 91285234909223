import * as React from 'react';
import IUrlTrend from '../../interfaces/IUrlTrend';
import IPlanData from '../../interfaces/IPlanData';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import UrlTrendRow from './UrlTrendRow';
import * as CSS from 'csstype';
import * as Utils from '../../utils';
import * as Plan from '../../models/plan'
import Graph from './Graph';

interface IUrlTrendOwnState {
	urlDataExpanded: boolean;
}
interface IUrlTrendOwnProps {
	SetPage: Function;
	trendData: IUrlTrend[];
	trendUrl: string;
	period:number;
	GetTrendData:Function;
	planData:IPlanData[];
	userPlanId:string;
}

export default class UrlTrend extends React.Component<IUrlTrendOwnProps, IUrlTrendOwnState> {
	public constructor(props: IUrlTrendOwnProps) {
		super(props);
		this.state = { urlDataExpanded: false };
	}

	private getAggDataPoints(): { min: number; max: number; median: number } {
		let arr: IUrlTrend[] = this.props.trendData.slice(0);
		if (!arr || arr.length == 0) {
			return { min: 0, max: 0, median: 0 };
		}
		arr.sort((a: IUrlTrend, b: IUrlTrend): number => {
			if (a.Totaltime > b.Totaltime) {
				return -1;
			} else {
				return 1;
			}
		});
		const max: number = arr[0].Totaltime;
		const min: number = arr[arr.length - 1].Totaltime;
		let median: number = 0;
		if (arr.length % 2 == 0) {
			//even
			median = (arr[Math.floor((arr.length - 2) / 2)].Totaltime + arr[Math.floor(arr.length / 2)].Totaltime) / 2;
		} else {
			//odd
			median = arr[Math.floor(arr.length / 2)].Totaltime;
		}
		return { min, max, median };
	}

	private displayMs(unixMilli: number): string {
		return Math.ceil(unixMilli / 1000 / 1000) + 'ms';
	}

	private onPeriodChange(e: React.ChangeEvent<HTMLSelectElement>) {
		let selectedTimeframe = Number(e.target.value); 
		
		if (isNaN(selectedTimeframe)) {
			console.log("selectedTimeframe is not a number", "value", e.target.value );
		}

		//cant select 
		if(!Plan.canSelectDuration(this.props.planData, this.props.userPlanId, selectedTimeframe)) {
			console.log("Cant select this period" );
			return;
		}

		this.props.GetTrendData(this.props.trendUrl, Number(e.target.value));

		console.log('selectedTimeframe', selectedTimeframe);
		let params = new Map<string, string>();
		params.set('period', e.target.value);
		params.set('url', this.props.trendUrl);
		this.props.SetPage(Constants.UrlTrendPage, params); //add params
		//do we have that data locally?
		//if not, fetch
	}

	public render(): React.ReactNode {
		if (!this.props.trendData) {
			return null;
		}
		let dataButtonText = 'Show Data';
		let dataButtonStyle = { display: 'none' };
		if (this.state.urlDataExpanded) {
			dataButtonText = 'Hide Data';
			dataButtonStyle.display = 'block';
		}
		let aggData = this.getAggDataPoints();
		const intervalDropdown = [
			{seconds:30 * 24 * 60 * 60, text:"1 month"}, 
			{seconds:7 * 24 * 60 * 60, text:"1 week"}, 
			{seconds:24 * 60 * 60, text:"1 day"}, 
			{seconds:60 * 60, text:"1 hour"}
		]
		return (
			<div>
				<div className="flex items-center absolute top-0">
					<img
						className="cursor-pointer h-4"
						src={Utils.getAssetUrl('font-awesome_4-7-0_arrow-left_256_0_007dff_none.png')}
						onClick={this.props.SetPage.bind(this, 'Overview', new Map<string, string>())}
					/>
					<p
						className="ml-4 cursor-pointer"
						style={{ color: '#007dff' }}
						onClick={this.props.SetPage.bind(this, 'Overview', new Map<string, string>())}
					>
						Back
					</p>
				</div>
				<p className="text-center text-xl">
					<span>{this.props.trendUrl}</span>
				</p>
				<div className="text-center"></div>
				<div className="flex flex-row gap-4 justify-center">
					<div className="my-1">
						<span className="mx-1">Period:</span>
						<select name="period" onChange={this.onPeriodChange.bind(this)} className="p-2">
						{intervalDropdown.map((v, i)=>{	
							if(!Plan.canSelectDuration(this.props.planData, this.props.userPlanId, v.seconds)) {
								return null;
							}
							if(v.seconds === this.props.period) {
								return <option key={i} value={v.seconds} selected> {v.text}</option>
							} else {
								return <option key={i} value={v.seconds}> {v.text}</option>
							}
						})}
						</select>
					</div>
					<p>Min:{this.displayMs(aggData.min)}</p>
					<p>Max:{this.displayMs(aggData.max)}</p>
					<p>Median:{this.displayMs(aggData.median)}</p>
				</div>

				<Graph graphData={this.props.trendData} />

				<div>
					<div>
						<button
							className="btn btn-blue-no-outline"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								this.setState({ urlDataExpanded: !this.state.urlDataExpanded });
							}}
						>
							{dataButtonText}
						</button>
					</div>
					<div style={dataButtonStyle}>
						{this.props.trendData.map((v: IUrlTrend, index: number) => {
							return <UrlTrendRow key={index} UrlData={v} />;
						})}
					</div>
				</div>
			</div>
		);
	}
}
