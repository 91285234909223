import * as React from 'react';
import IUrlTrend from '../../interfaces/IUrlTrend';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import * as CSS from 'csstype';
import * as Utils from '../../utils';

export default class UrlTrendRow extends React.Component<{ UrlData: IUrlTrend }, any> {
	private displayResponseTime(responseTime: number): React.ReactNode {
		return <span>{Utils.displayResponseTime(responseTime)}</span>;
	}
	public render(): React.ReactNode {
		const v = this.props.UrlData;
		return (
			<div className="flex flex-row justify-between border-gray-400 border-b">
				<p className="m-4">{Utils.getDateString(v.Time)}</p>
				<p className="m-4">{this.displayResponseTime(v.Totaltime)}</p>
				<p className="m-4">Status: {v.StatusCode}</p>
			</div>
		);
	}
}
