import * as actions from '../../actions/actionTypes';
import * as Constants from '../../../constants';

import IUIState from '../interfaces/IUIState';

const initialState: IUIState = {
	component: Constants.LoginPage,
	params: new Map<string, string>(),
	addUrlWindowState: false,
	globalMessage: '',
	initComplete: false,
	refreshingToken: false,
	settingsLoading: Constants.NOT_LOADED,
	loginLoading: Constants.NOT_LOADED,
	requestInviteLoading: Constants.NOT_LOADED,
	registerUserLoading: Constants.NOT_LOADED,
	registerUserErrorCode: Constants.ERROR_CODE_NO_ERROR,
	settingsErrorMesssage: '',
	settingsSuccessMesssage: '',
	loginErrorCode: Constants.ERROR_CODE_NO_ERROR,
	validateResetPasswordTokenLoading: Constants.NOT_LOADED,
	resetpasswordFormTokenValidity: false,
	validateResetPasswordTokenErrorCode: Constants.ERROR_CODE_NO_ERROR,
	resetPasswordResetErrorCode: Constants.ERROR_CODE_NO_ERROR,
	resetpasswordRequestLoading: Constants.NOT_LOADED,
	requestPasswordLoading: Constants.NOT_LOADED,
};

const ui = (state: IUIState = initialState, action: any) => {
	switch (action.type) {
		case actions.RESET_STATE: {
			return initialState;
		}

		case actions.SET_PAGE: {
			const newState: IUIState = Object.assign({}, state);
			newState.component = action.component;
			newState.params = action.params;
			return newState;
		}

		case actions.SHOW_ADD_URL_WINDOW: {
			const newState: IUIState = Object.assign({}, state);
			newState.addUrlWindowState = action.data;
			return newState;
		}

		case actions.SET_GLOBAL_MESSAGE: {
			const newState: IUIState = Object.assign({}, state);
			newState.globalMessage = action.data;
			return newState;
		}

		case actions.SET_INIT_COMPLETE: {
			const newState: IUIState = Object.assign({}, state);
			newState.initComplete = action.data;
			return newState;
		}

		case actions.SET_REFRESHING_TOKEN: {
			const newState: IUIState = Object.assign({}, state);
			newState.refreshingToken = action.data;
			return newState;
		}

		case actions.SET_CHANGE_PASSWORD_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.settingsLoading = action.data;
			return newState;
		}

		case actions.SET_CHANGE_PASSWORD_ERROR_MESSAGE: {
			const newState: IUIState = Object.assign({}, state);
			newState.settingsErrorMesssage = action.data;
			return newState;
		}

		case actions.SET_CHANGE_PASSWORD_SUCCESS_MESSAGE: {
			const newState: IUIState = Object.assign({}, state);
			newState.settingsSuccessMesssage = action.data;
			return newState;
		}

		case actions.SET_LOGIN_ERROR_CODE: {
			const newState: IUIState = Object.assign({}, state);
			newState.loginErrorCode = action.data;
			return newState;
		}

		case actions.SET_LOGIN_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.loginLoading = action.data;
			return newState;
		}

		case actions.SET_REQUEST_INVITE_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.requestInviteLoading = action.data;
			return newState;
		}

		case actions.SET_REGISTER_USER_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.registerUserLoading = action.data;
			newState.registerUserErrorCode = action.errorCode;
			return newState;
		}

		case actions.SET_VALIDATE_RESET_PASSWORD_TOKEN_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.validateResetPasswordTokenLoading = action.data;
			return newState;
		}

		case actions.SET_REQUEST_PASSWORD_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.requestPasswordLoading = action.data;
			return newState;
		}

		case actions.SET_REQUEST_PASSWORD_REQUEST_LOADED: {
			const newState: IUIState = Object.assign({}, state);
			newState.resetpasswordRequestLoading = action.data;
			return newState;
		}

		case actions.SET_REQUEST_PASSWORD_FORM_TOKEN_VALIDITY: {
			const newState: IUIState = Object.assign({}, state);
			newState.resetpasswordFormTokenValidity = action.data;
			return newState;
		}

		case actions.SET_VALIDATE_RESET_PASSWORD_TOKEN_ERROR_CODE: {
			const newState: IUIState = Object.assign({}, state);
			newState.validateResetPasswordTokenErrorCode = action.data;
			return newState;
		}

		case actions.SET_RESET_PASSWORD_RESET_ERROR_CODE: {
			const newState: IUIState = Object.assign({}, state);
			newState.resetPasswordResetErrorCode = action.data;
			return newState;
		}

		default: {
			return state;
		}
	}
};

export default ui;
