import * as React from 'react';
import * as Constants from '../../constants';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

interface ISimpleButtonOwnState {}
interface ISimpleButtonOwnProps {
	text: string;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

//class renders the home page for user
export default class SimpleButton extends React.Component<ISimpleButtonOwnProps, ISimpleButtonOwnState> {
	public componentDidMount() {}

	public render(): React.ReactNode {
		const buttonStyle: CSS.Properties = {
			backgroundColor: '#fff',
			color: '#007dff',
			border: '1px #007dff solid',
			borderRadius: '0.3em',
			fontWeight: 'bold',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			margin: '1em',
			padding: '0.5em 1em',
			cursor: 'pointer',
		};
		return (
			<button className="btn btn-blue-outline m-2" onClick={this.props.onClick}>
				{this.props.text}
			</button>
		);
	}
}
