import * as React from 'react';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IOverviewData from '../../interfaces/IOverviewData';
import * as CSS from 'csstype';
import * as Utils from '../../utils';
import Popup from '../components/Popup';

interface IOverviewRowOwnState {
	removeOpen: boolean;
	confirmText: string;
	confirmErrorMessage: string;
}
interface IOverviewRowOwnProps {
	UrlData: IOverviewData;
	SetPage: Function;
	RemoveUrl: Function;
}

//class renders the home page for user
export default class OverviewRow extends React.Component<IOverviewRowOwnProps, IOverviewRowOwnState> {
	public constructor(props: IOverviewRowOwnProps) {
		super(props);
		this.state = { removeOpen: false, confirmText: '', confirmErrorMessage: '' };
	}
	public componentDidMount() {}

	private enableTrendLink(val: number): boolean {
		return val < 0 ? false : true;
	}

	private getIndicator(val: number): React.ReactNode {
		if (val < 0) {
			return <span>Newly added</span>;
		}
		if (val < 99) {
			return <span className="text-red-700">down</span>; //red
		}
		if (val < 99.5) {
			return <span className="text-yellow-700">intermittent</span>; //yellow
		}
		return <span className="text-green-700">up</span>; //green
	}
	private displayResponseTime(responseTime: number): React.ReactNode {
		if (responseTime === 0) {
			return <span>---</span>;
		}
		return <span>{Utils.displayResponseTime(responseTime)}</span>;
	}

	private moreInfo(url: string) {
		// body...
		//console.log(url);
		//link to the action for loading more info
		//function call
		//this.props.GetTrendData(url);
		const params: Map<string, string> = new Map<string, string>();
		params.set('url', url);
		this.props.SetPage(Constants.UrlTrendPage, params);
	}
	private confirmCheckMessage() {
		this.setState({
			confirmErrorMessage:
				this.state.confirmText !== 'CONFIRM' ? "Please enter the text 'CONFIRM' below to proceed" : null,
		});
	}
	private removeUrl(url: string) {
		if (this.state.confirmText == 'CONFIRM') {
			this.props.RemoveUrl(url);
			this.setState({ removeOpen: false });
		}
		this.confirmCheckMessage();
	}
	private popupComp(url: string): React.ReactElement {
		return (
			<div className="text-center">
				<h2>Remove URL</h2>
				<p>
					Do you want to remove <strong>{url}</strong> from all tracking?
				</p>
				<p>This will remove all data associated with this URL</p>
				<p>
					<strong>The data can not be recovered once deleted</strong>
				</p>

				{this.state.confirmErrorMessage == '' ? (
					<p>Type in 'CONFIRM' to proceed</p>
				) : (
					<p className="text-red-700">{this.state.confirmErrorMessage}&nbsp;</p>
				)}
				<div className="my-2">
					<input
						className="textbox-outline-blue"
						type="text"
						onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
							this.setState({ confirmText: e.currentTarget.value });
						}}
					/>
				</div>
			</div>
		);
	}

	public render(): React.ReactNode {
		const v: IOverviewData = this.props.UrlData;
		return (
			<div className="flex flex-row border-gray-400 border-b justify-between items-center">
				<div>
					<img
						className="logo cursor-pointer"
						src={Utils.getAssetUrl('ligature-symbols_2-11-0_delete_256_0_ff001f_none.png')}
						onClick={() => {
							this.setState({ removeOpen: true });
						}}
					/>
				</div>
				<div className="flex flex-col md:flex-row md:w-5/6">
					<p className="m-2 md:m-4 w-full md:w-2/4 flex-grow">{v.Url}</p>
					<div className="flex flex-row mb-2 justify-around md:justify-between md:w-2/6">
						<p className="m-1 md:m-4 md:w-1/3">{this.getIndicator(v.Indicator * 100)}</p>
						<p className="m-1 md:m-4 md:w-2/3">{this.displayResponseTime(v.AvgResponse)}</p>
					</div>
				</div>
				<div
					className={
						this.enableTrendLink(v.Indicator)
							? 'flex items-center cursor-pointer'
							: 'hidden flex items-center cursor-pointer'
					}
					onClick={this.moreInfo.bind(this, v.Url)}
				>
					<p>
						<img
							className="h-4"
							src={Utils.getAssetUrl('font-awesome_4-7-0_chevron-right_256_0_007dff_none.png')}
						/>
					</p>
				</div>
				<Popup
					IsOpen={this.state.removeOpen}
					component={this.popupComp(v.Url)}
					actionButtonText="Remove"
					actionButtonOnClick={this.removeUrl.bind(this, v.Url)}
					onClose={() => {
						this.setState({ removeOpen: false, confirmText: '', confirmErrorMessage: '' });
					}}
				/>
			</div>
		);
	}
}
