import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './redux/store';

import App from './Main/root/App';
import { InitAction } from './Main/actions/index';

const rootElement = document.getElementById('wrapper');

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	rootElement,
);

//browser back button
window.onpopstate = function(event: PopStateEvent) {
	store.dispatch<any>(InitAction());
};
