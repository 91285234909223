import * as React from 'react';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import IOverviewData from '../../interfaces/IOverviewData';
import IPlanData from '../../interfaces/IPlanData';
import ICombinedState from '../reducers/interfaces/ICombinedState';
//import { IDispatch } from 'react-redux';
import Overview from './Overview';
import AddUrl from './AddUrl';
import {
	GetOverviewAction,
	SetPageAction,
	AddUrlAction,
	ShowAddUrlWindowAction,
	RemoveUrlAction,
} from '../actions';
import * as CSS from 'csstype';
import * as Utils from '../../utils';
import * as Plan from '../../models/plan'

//type IDispatch = (action: Action) => void;

interface IOverviewContainerOwnState {}
interface IOverviewContainerOwnProps {}
interface IOverviewContainerDispatchProps {
	GetOverviewData: Function;
	SetPage: Function;
	AddUrl: Function;
	ShowAddUrlWindow: Function;
	RemoveUrl: Function;
}
interface IOverviewContainerStateProps {
	overviewData: IOverviewData[];
	overviewDataTime: number;
	addUrlWindowState: boolean;
	profileDataLoaded: string;
	planData: IPlanData[];
	userPlanId: string;
}

type IOverviewContainerProps = IOverviewContainerOwnProps &
	IOverviewContainerDispatchProps &
	IOverviewContainerStateProps;

//class renders the home page for user
class OverviewContainer extends React.Component<IOverviewContainerProps, IOverviewContainerOwnState> {
	public componentDidMount() {
		console.log('OverviewContainer::componentDidMount');
		//if component data is not loaded within last 1 hour
		const diff: number = (this.props.overviewDataTime - Date.now()) / 1000;
		if (this.props.overviewDataTime === 0 || diff > 3600) {
			this.props.GetOverviewData(null);
		}
		//load the data
	}

	public render(): React.ReactNode {

		const  urlLimit = Plan.getUrlLimit(this.props.planData, this.props.userPlanId);
		
		return (
			<Overview
				overviewData={this.props.overviewData}
				GetOverviewData={this.props.GetOverviewData}
				SetPage={this.props.SetPage}
				AddUrl={this.props.AddUrl}
				ShowAddUrlWindow={this.props.ShowAddUrlWindow}
				addUrlWindowState={this.props.addUrlWindowState}
				RemoveUrl={this.props.RemoveUrl}
				ProfileDataLoaded={this.props.profileDataLoaded}
				urlCount={this.props.overviewData.length}
				urlLimit={urlLimit}
			/>
		);
	}
}
const mapStateToProps = (state: ICombinedState, ownProps: IOverviewContainerProps): IOverviewContainerStateProps => {
	return {
		overviewData: state.main.overviewData,
		addUrlWindowState: state.ui.addUrlWindowState,
		overviewDataTime: state.main.overviewDataTime,
		profileDataLoaded: state.main.profileDataLoaded,
		planData: state.main.planData,
		userPlanId: state.user.planId,
	};
};

const mapDispatchToProps = (dispatch: Function, ownProps: IOverviewContainerProps): IOverviewContainerDispatchProps => {
	return {
		GetOverviewData: (email: string) => {
			dispatch(GetOverviewAction(email));
		},
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		AddUrl: (url: string, successCode: number, targetResponse: number) => {
			dispatch(AddUrlAction(url, successCode, targetResponse));
		},
		ShowAddUrlWindow: (show: boolean) => {
			dispatch(ShowAddUrlWindowAction(show));
		},
		RemoveUrl: (url: string) => {
			dispatch(RemoveUrlAction(url));
		},
	};
};

export default connect<IOverviewContainerStateProps, IOverviewContainerDispatchProps, IOverviewContainerOwnProps>(
	mapStateToProps,
	mapDispatchToProps,
)(OverviewContainer);
