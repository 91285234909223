import * as React from 'react';
import IQUrl from '../../interfaces/IQUrl';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import { SetPageAction, InitAction, Logout } from '../actions';
import OverviewContainer from '../home/OverviewContainer';
import UrlTrendContainer from '../details/UrlTrendContainer';
import LoginContainer from '../login/LoginContainer';
import SettingsContainer from '../settings/SettingsContainer';
import AboutContainer from '../about/AboutContainer';
import PricingContainer from '../pricing/PricingContainer';
import Header from '../components/Header';
import Footer from '../components/Footer';
import ErrorMessage from '../components/ErrorMessage';
import ICombinedState from '../reducers/interfaces/ICombinedState';

import LoadingBars from '../components/LoadingBars';
import * as CSS from 'csstype';

interface IAppOwnState {}
interface IAppOwnProps {}
interface IAppDispatchProps {
	SetPage: Function;
	Init: Function;
	Logout: Function;
}
interface IAppStateProps {
	url: string;
	profileDataLoaded: string;
	trendUrlDataLoaded: string;
	component: string;
	globalMessage: string;
	params: Map<string, string>;
	initComplete: boolean;
	refreshingToken: boolean;
	email: string;
}

type IAppProps = IAppOwnProps & IAppDispatchProps & IAppStateProps;

class App extends React.Component<IAppProps, IAppOwnState> {
	constructor(props: IAppProps) {
		super(props);
	}

	public componentDidMount() {
		console.log('App::componentDidMount');
		this.props.Init();
	}

	//get the component from route
	private getPage(): React.ReactNode {
		const showLoading: boolean = !(
			this.props.trendUrlDataLoaded === Constants.LOADED || this.props.profileDataLoaded === Constants.LOADED
		);

		console.log(this.props.initComplete, showLoading);

		//right not they are both coupled
		if (!this.props.initComplete || this.props.refreshingToken) {
			return <LoadingBars />;
		}

		//const parsedUrl: IQUrl = Utils.urlToComponentAndProps();
		const props: any = {};
		for (let [key, value] of this.props.params.entries()) {
			props[key] = value;
		}

		const p: string = this.props.component;

		//switch (this.props.component) {
		switch (this.props.component) {
			case Constants.UrlTrendPage:
				return (
					<UrlTrendContainer
						url={this.props.params.get('url')}
						period={this.props.params.get('period')}
						SetPage={this.props.SetPage}
					/>
				);

			case Constants.LoginPage:
				return <LoginContainer mode={Constants.LOGIN_MODE} />;

			case Constants.SignUpPage:
				return <LoginContainer mode={Constants.SIGNUP_MODE} />;

			case Constants.ResetPasswordPage:
				return <LoginContainer mode={Constants.RESET_PASSWORD_MODE} />;

			case Constants.ResetPasswordForm:
				return <LoginContainer mode={Constants.RESET_PASSWORD_FORM_MODE} />;

			case Constants.SettingsPage:
				return <SettingsContainer />;

			case Constants.AboutPage:
				return <AboutContainer />;

			case Constants.PricingPage:
				return <PricingContainer />;

			case Constants.Overview:
			default:
				return <OverviewContainer />;
		}
	}

	public render(): React.ReactNode {
		const contentStyle = {
			backgroundColor: '#fff',
			color: '#222',
			padding: '2rem',
			flexGrow: 1,
		};
		const appStyle = {
			height: '100%',
			display: 'flex',
			//flexDirection: 'column',
			//flexDirection: 'column' as CSS.FlexDirectionProperty,
			flexDirection: 'column' as const,
		};
		return (
			<div className="app" style={appStyle}>
				<Header LoggedIn={this.props.email != ''} SetPage={this.props.SetPage} Logout={this.props.Logout} />
				<ErrorMessage message={this.props.globalMessage} />
				<div className="content relative" style={contentStyle}>
					{this.getPage()}
				</div>
				<Footer />
			</div>
		);
	}
}

const mapStateToProps = (state: ICombinedState, ownProps: IAppProps): IAppStateProps => {
	return {
		url: state.main.url,
		profileDataLoaded: state.main.profileDataLoaded,
		trendUrlDataLoaded: state.trendUrl.trendUrlDataLoaded,
		component: state.ui.component,
		params: state.ui.params,
		globalMessage: state.ui.globalMessage,
		initComplete: state.ui.initComplete,
		refreshingToken: state.ui.refreshingToken,
		email: state.user.email,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: IAppProps): IAppDispatchProps => {
	return {
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		Init: () => {
			dispatch(InitAction());
		},
		Logout: () => {
			dispatch(Logout());
		},
	};
};

export default connect<IAppStateProps, IAppDispatchProps, IAppOwnProps>(mapStateToProps, mapDispatchToProps)(App);
