import * as React from 'react';
import * as Constants from '../../constants';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

import SimpleButton from './SimpleButton';

//import * as styles from './Overview.css';

interface IPopupOwnState {}
interface IPopupOwnProps {
	IsOpen: boolean;
	component: React.ReactElement;
	//actionButton: React.ReactElement;
	onClose: Function;
	actionButtonText: string;
	actionButtonOnClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	closeButtonText?: string;
}

//class renders the home page for user
export default class Popup extends React.Component<IPopupOwnProps, IPopupOwnState> {
	constructor(props: IPopupOwnProps) {
		super(props);
		//this.state = {};
	}
	public componentDidMount() {}

	private onClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		this.props.onClose();
	}

	public render(): React.ReactNode {
		if (!this.props.IsOpen) {
			return null;
		}
		const formItems: CSS.Properties = {
			display: 'flex',
			flexDirection: 'row',
			margin: '1em',
		};

		return (
			<div className="popup-container">
				<div className="popup-container-body">
					<div className="w-full">{this.props.component}</div>
					<div className="flex flex-row">
						{this.props.actionButtonText !== '' ? (
							<SimpleButton text={this.props.actionButtonText} onClick={this.props.actionButtonOnClick} />
						) : null}
						<SimpleButton
							text={this.props.closeButtonText === undefined ? 'Close' : this.props.closeButtonText}
							onClick={this.onClose.bind(this)}
						/>
					</div>
				</div>
				<div className="popup-container-bg" onClick={this.onClose.bind(this)} />
			</div>
		);
	}
}
