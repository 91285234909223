import * as Constants from './constants';
import IQUrl from './interfaces/IQUrl.d';
import IOverviewData from './interfaces/IOverviewData';

export function displayResponseTime(responseTime: number): React.ReactNode {
	return Math.ceil(responseTime / 1000000) + 'ms';
}

export function getAssetUrl(imagePath: string): string {
	if (isDev()) {
		return '../assets/' + imagePath;
	}

	return 'https://www.robopoller.com/assets/' + imagePath;
}

export function isDev() {
	return window.location.href.includes('dev');
}

const urlComponentMap: any = {
	details: Constants.UrlTrendPage,
	login: Constants.LoginPage,
	home: Constants.Overview,
	settings: Constants.SettingsPage,
	about: Constants.AboutPage,
	pricing: Constants.PricingPage,
	signup: Constants.SignUpPage,
	resetpassword: Constants.ResetPasswordPage,
	resetpasswordForm: Constants.ResetPasswordForm,
};
//populated from above
let componentUrlMap: any = undefined;

function makeComponentUrlMap() {
	if (componentUrlMap === undefined) {
		componentUrlMap = {};
		const keys = Object.keys(urlComponentMap);
		for (let i = keys.length - 1; i >= 0; i--) {
			const key = keys[i];
			componentUrlMap[urlComponentMap[key]] = key;
		}
	}
}

export function isValidComponent(component: string): boolean {
	makeComponentUrlMap();
	return componentUrlMap[component] !== undefined;
}

export function urlToComponent(link: string): string {
	if (urlComponentMap[link] !== undefined) {
		return urlComponentMap[link];
	}
	return Constants.Overview;
}

export function componentToUrl(comp: string): string {
	makeComponentUrlMap();
	console.log(comp, componentUrlMap);
	if (componentUrlMap[comp] !== undefined) {
		return componentUrlMap[comp];
	}

	return 'home';
}

export function logConsole() {}

export function isComponentNoAuth(comp: string): boolean {
	const auth: any = {};
	auth[Constants.LoginPage] = false;
	auth[Constants.SignUpPage] = false;
	auth[Constants.AboutPage] = false;
	auth[Constants.PricingPage] = false;
	auth[Constants.ResetPasswordPage] = false;
	auth[Constants.ResetPasswordForm] = false;
	console.log('Component:', comp, auth[comp], auth[comp] === false);
	return auth[comp] === false;
}

export function urlToComponentAndProps(): IQUrl {
	let page: string;
	let queryMap: Map<string, string> = new Map();

	let ret: IQUrl = { component: '', queryMap: new Map() };

	const urlAnchor: string[] = window.location.href.split('#');

	if (urlAnchor.length != 2) {
		page = '';
	} else {
		const pageComponents: string[] = urlAnchor[1].split('/');
		page = urlToComponent(pageComponents[0]);

		if (pageComponents.length > 2 && pageComponents.length % 2 == 1) {
			for (var i = 1; i < pageComponents.length; i += 2) {
				pageComponents[i];
				queryMap.set(pageComponents[i], decodeURIComponent(pageComponents[i + 1]));
			}
		}
	}

	ret.component = page;
	ret.queryMap = queryMap;

	return ret;
}

export function storeLocal(key: string, value: string): boolean {
	localStorage.setItem(key, value);
	return true;
}

export function getLocal(key: string): string {
	return localStorage.getItem(key);
}

export function clearAllLocals() {
	localStorage.clear();
}

export function isTokenWellFormed(token: string, tokenExpiry: number): boolean {
	return token !== null && token != '' && tokenExpiry !== 0 && tokenExpiry !== NaN;
}

export function isTokenValid(token: string, tokenExpiry: number): boolean {
	return isTokenWellFormed(token, tokenExpiry) && !isTokenExpired(token, tokenExpiry);
}

export function isTokenExpired(token: string, tokenExpiry: number): boolean {
	console.log('isTokenExpired', tokenExpiry, token);
	if (isTokenWellFormed(token, tokenExpiry)) {
		const currentUnixTimestamp: number = Date.now() / 1000;
		if (tokenExpiry + Constants.TOKEN_EXPIRY_TTL - currentUnixTimestamp > 0) {
			return false;
		}
	}
	return true;
}

export function isValidEmail(email: string): boolean {
	return email !== '' && email !== null && new RegExp('^\\w+([.-]?\\w+)*@\\w+([.-]?\\w+)*(.\\w{2,3})+$').test(email);
}

export function isNewlyAddedUrl(urlData: IOverviewData): boolean {
	return urlData.Indicator === -1;
}

export function getDateString(unixTs: number): string {
	const d = new Date(unixTs * 1000);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

	return (
		'' +
		months[d.getMonth()] +
		', ' +
		(d.getDate() < 10 ? '0' + d.getDate() : d.getDate()) +
		' ' +
		(d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) +
		':' +
		(d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes())
	);

	return (
		'' +
		(d.getDay() < 10 ? '0' + d.getDay() : d.getDay()) +
		'/' +
		(d.getMonth() < 10 ? '0' + d.getMonth() : d.getMonth()) +
		'/' +
		d.getFullYear() +
		' ' +
		(d.getHours() < 10 ? '0' + d.getHours() : d.getHours()) +
		':' +
		(d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes())
	);
}
