import * as React from 'react';
import * as Constants from '../../constants';
import { connect } from 'react-redux';
import { SetPageAction, ChangePasswordAction } from '../actions';
import IUrlTrend from '../../interfaces/IUrlTrend';
import ICombinedState from '../reducers/interfaces/ICombinedState';
import Password from '../components/Password';

interface ISettingsContainerOwnProps {}

interface ISettingsContainerOwnState {
	oldPassword: string;
	newPassword: string;
}

interface ISettingsContainerDispatchProps {
	ChangePassword: Function;
}
interface ISettingsContainerStateProps {
	loading: string;
	successMessage: string;
	errorMessage: string;
}

type IOverviewContainerProps = ISettingsContainerOwnProps &
	ISettingsContainerDispatchProps &
	ISettingsContainerStateProps;

class SettingsContainer extends React.Component<IOverviewContainerProps, ISettingsContainerOwnState> {
	private oldPasswordField: React.RefObject<HTMLInputElement>;
	private newPasswordField: React.RefObject<HTMLInputElement>;

	public constructor(props: IOverviewContainerProps) {
		super(props);
		this.state = { oldPassword: '', newPassword: '' };

		this.oldPasswordField = React.createRef();
		this.newPasswordField = React.createRef();
	}

	public componentDidMount() {}

	private onKeyUp(val: string, e: Event) {
		const oldPasswordDom = this.oldPasswordField?.current?.value;
		const newPasswordDom = this.newPasswordField?.current?.value;
		if (
			val == 'newPassword' &&
			this.state.oldPassword == '' &&
			(oldPasswordDom !== null || oldPasswordDom !== undefined || oldPasswordDom !== '')
		) {
			this.setState(Object.assign(this.state, { oldPassword: oldPasswordDom }));
		}
		if (
			val == 'oldPassword' &&
			this.state.newPassword == '' &&
			(newPasswordDom !== null || newPasswordDom !== undefined || newPasswordDom !== '')
		) {
			this.setState(Object.assign(this.state, { oldPassword: oldPasswordDom }));
		}
		let change: any = {};
		change[val] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign(this.state, change));
	}

	//true when all good
	private checkInputs(): boolean {
		return (
			this.state.oldPassword !== '' &&
			this.state.newPassword !== '' &&
			this.state.newPassword !== this.state.oldPassword
		);
	}

	private onChangePassword() {
		if (this.checkInputs()) {
			this.props.ChangePassword(this.state.oldPassword, this.state.newPassword);
		} else {
			//display error message
		}
	}

	public render(): React.ReactNode {
		const classname: string = 'textbox-outline block w-full';
		return (
			<div className="p-5 w-full md:w-1/2 mx-auto">
				<h2>Change Password</h2>
				<div className="flex flex-col mb-4">
					<div className="w-full my-2">
						<Password
							text={this.state.oldPassword}
							onKeyUp={this.onKeyUp.bind(this, 'oldPassword')}
							placeholder="Old Password"
							fieldRef={this.oldPasswordField}
						/>
					</div>
					<div className="w-full  my-2">
						<span className="text-xs text-red-700">
							{this.state.oldPassword !== '' && this.state.oldPassword === this.state.newPassword
								? 'Old and new passwords are same. '
								: ''}
							&nbsp;
						</span>

						<Password
							text={this.state.newPassword}
							onKeyUp={this.onKeyUp.bind(this, 'newPassword')}
							placeholder="New Password"
						/>
					</div>
					<div className="w-full my-2">
						<span className="text-xs text-red-700">
							{this.props.errorMessage}
							&nbsp;
						</span>
						<span className="text-xs text-green-700">
							{this.props.successMessage}
							&nbsp;
						</span>
					</div>
					<button
						disabled={this.props.loading === Constants.LOADING || !this.checkInputs()}
						className="btn btn-blue-outline  my-2"
						onClick={this.onChangePassword.bind(this)}
					>
						Change Password
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (
	state: ICombinedState,
	ownProps: ISettingsContainerStateProps,
): ISettingsContainerStateProps => {
	return {
		loading: state.ui.settingsLoading,
		successMessage: state.ui.settingsSuccessMesssage,
		errorMessage: state.ui.settingsErrorMesssage,
	};
};

const mapDispatchToProps = (dispatch: Function, ownProps: ISettingsContainerDispatchProps) => {
	return {
		ChangePassword: (oldPass: string, newPass: string) => {
			console.log(oldPass, newPass);
			dispatch(ChangePasswordAction(oldPass, newPass));
		},
	};
};

export default connect<ISettingsContainerStateProps, ISettingsContainerDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(SettingsContainer);
