import * as React from 'react';
import * as Constants from '../../constants';
import { connect } from 'react-redux';
import { SetPageAction, GetUrlTrendAction } from '../actions';
import UrlTrend from './UrlTrend';
import IUrlTrend from '../../interfaces/IUrlTrend';
import IPlanData from '../../interfaces/IPlanData';

interface IProps {
	trendUrl?: string;
	SetPage?: Function;
	GetTrendData?: Function;
	trend?: IUrlTrend[];
	url?: string;
	period?: string;
	planData?: IPlanData[];
	userPlanId?: string;
}

class UrlTrendContainer extends React.Component<IProps, any> {
	public componentDidMount() {
		console.log("componentDidMount UrlTrendContainer",this.getPeriod())
		this.props.GetTrendData(this.props.url, this.getPeriod());
	}

	private getPeriod(): number {
		let period: number = Number(this.props.period);
		if (isNaN(period)) {
			period = Constants.PERIOD_WEEK_VALUE;
		}
		return period;
	}

	private getDateString(ts: number, interval: string): string {
		const date = new Date(ts * 1000);
		switch (interval) {
			case 'day':
				return '' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear();
			default:
				//hourly
				return '' + date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear() + ':' + date.getHours();
		}
	}

	private normalizeData(interval: string, algorithm: string): IUrlTrend[] {
		let newData: any = {};
		for (let i = 0; i < this.props.trend.length; i++) {
			let elem = this.props.trend[i];
			//this is where normalization happen
			const dateString: string = this.getDateString(elem.Time, interval);
			//console.log('dateString', dateString, elem.Time, elem);
			if (newData[dateString] === undefined) {
				newData[dateString] = { sum: elem, count: 1 };
			} else {
				newData[dateString].sum.Totaltime = newData[dateString].sum.Totaltime + elem.Totaltime;
				newData[dateString].count++;
			}
		}

		let retData: IUrlTrend[] = [];
		const k = Object.keys(newData);
		for (let i = 0; i < k.length; i++) {
			retData[i] = newData[k[i]].sum;
			retData[i].Totaltime = retData[i].Totaltime / newData[k[i]].count;
		}
		return retData;
	}

	/*
		ConnectTime: 10600819
		ConnectionPool: 13181030
		Dns: 2389284
		Totaltime: 42110228
		Ttfb: 42028498
	*/

	private getGraphData() {
		let interval: number;
		switch (this.getPeriod()) {
			case Constants.PERIOD_MONTH_VALUE:
				return this.normalizeData('day', 'avg');
				break;
			case Constants.PERIOD_WEEK_VALUE:
				return this.normalizeData('day', 'avg');
				break;
			case Constants.PERIOD_DAY_VALUE:
				return this.normalizeData('hour', 'avg');
				break;
			case Constants.PERIOD_HOUR_VALUE:
				return this.normalizeData('minute', 'avg');
				break;
		}
	}

	public render(): React.ReactNode {
		
		return <UrlTrend 
					SetPage={this.props.SetPage} 
					trendUrl={this.props.trendUrl} 
					trendData={this.getGraphData()} 
					period={this.getPeriod()} 
					GetTrendData={this.props.GetTrendData}
					planData={this.props.planData}
					userPlanId={this.props.userPlanId}
				/>;
	}
}

const mapStateToProps = (state: any, ownProps: IProps): IProps => {
	return {
		trendUrl: state.trendUrl.trendUrl,
		trend:
			state.trendUrl.trend !== null && state.trendUrl.trend.get(state.trendUrl.trendUrl) !== undefined
				? state.trendUrl.trend.get(state.trendUrl.trendUrl)
				: [],
		planData: state.main.planData,
		userPlanId: state.user.planId,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
	return {
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		GetTrendData: (url: string, period: number, interval?: number) => {
			dispatch(GetUrlTrendAction(url, period, interval));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UrlTrendContainer);
