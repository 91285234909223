import IPlanData from '../interfaces/IPlanData';

function getLimitForDimention(planData:IPlanData[], userPlanId:string, dimentionId:string):number {
	if(planData === undefined || planData === null) {
		return null;
	}
	for (let i = 0; i < planData.length; i++) {
		if (planData[i].planId == userPlanId) {
			for (let j = 0; j < planData[i].dimentions.length; j++) {
				if (planData[i].dimentions[j].id == dimentionId) {
					return planData[i].dimentions[j].limit;
				}
			}
		}
	}
	return null;
}

export function getUrlLimit(planData:IPlanData[], userPlanId:string):number {

	const limit:number = getLimitForDimention(planData, userPlanId, 'url_count');
	return limit===null?0:limit
}


export function canSelectDuration(planData:IPlanData[], userPlanId:string, duration:number):boolean {
	const refreshFrequency:number = getLimitForDimention(planData, userPlanId, 'url_refresh_frequency');

	if(refreshFrequency === null) {
		return false
	}

	return duration > refreshFrequency
}