import * as actions from './actionTypes';
import IUrlData from '../../interfaces/IUrlData';
import IProfileData from '../../interfaces/IProfileData';
import IOverviewData from '../../interfaces/IOverviewData';
import IUrlTrend from '../../interfaces/IUrlTrend';
import ILoginResponse from '../../interfaces/ILoginResponse';
import IQUrl from '../../interfaces/IQUrl';
import ICombinedState from '../reducers/interfaces/ICombinedState';

import * as Constants from '../../constants';
import * as Utils from '../../utils';
//import { fetch } from '../../stubs/fetch-stub';

export const quarkFetch = async (
	reqUrl: string,
	fetchOptions: {},
	state: ICombinedState,
	dispatch: Function,
): Promise<any> => {
	let url: string = reqUrl;
	if (state.user.token != '') {
		url = reqUrl + '?token=' + state.user.token;
	}
	const res = await fetch(url, fetchOptions);
	if (res.status === 401) {
		Utils.clearAllLocals();
		dispatch(SetPageAction(Constants.LoginPage, new Map<string, string>()));
		throw 401;
	}
	return res;
};

export const UpdateProfileUrlData = (data: IProfileData) => ({
	type: actions.UPDATE_PROFILE_URL_DATA,
	data: data,
});

export const SetProfileUrlDataLoaded = (data: string) => ({
	type: actions.SET_PROFILE_URL_DATA_LOADED,
	data: data,
});

export const SetTrendUrlDataLoaded = (data: string) => ({
	type: actions.SET_URL_TREND_DATA_LOADED,
	data: data,
});
export const SetLoginLoaded = (data: string) => ({
	type: actions.SET_LOGIN_LOADED,
	data: data,
});
export const SetRequestInviteLoaded = (data: string) => ({
	type: actions.SET_REQUEST_INVITE_LOADED,
	data: data,
});

export const SetRegisterUserLoaded = (data: string, errorCode: number) => ({
	type: actions.SET_REGISTER_USER_LOADED,
	data: data,
	errorCode: errorCode,
});

export const SetRequestPasswordLoaded = (data: string) => ({
	type: actions.SET_REQUEST_PASSWORD_LOADED,
	data: data,
});
export const SetValidateResetPasswordTokenLoaded = (data: string) => ({
	type: actions.SET_VALIDATE_RESET_PASSWORD_TOKEN_LOADED,
	data: data,
});
export const SetResetPasswordRequestLoaded = (data: string) => ({
	type: actions.SET_REQUEST_PASSWORD_REQUEST_LOADED,
	data: data,
});

export const SetRequestPasswordFormTokenValidity = (data: boolean) => ({
	type: actions.SET_REQUEST_PASSWORD_FORM_TOKEN_VALIDITY,
	data: data,
});

export const SetAddUrlLoaded = (data: string, url: string) => ({
	type: actions.SET_ADD_URL_LOADED,
	data: data,
	url: url,
});
export const SetRemoveUrlLoaded = (data: string, url: string) => ({
	type: actions.SET_REMOVE_URL_LOADED,
	data: data,
	url: url,
});
export const SetChangePasswordLoaded = (data: string) => ({
	type: actions.SET_CHANGE_PASSWORD_LOADED,
	data: data,
});
export const SetChangePasswordSuccessMessage = (data: string) => ({
	type: actions.SET_CHANGE_PASSWORD_SUCCESS_MESSAGE,
	data: data,
});
export const SetChangePasswordErrorMessage = (data: string) => ({
	type: actions.SET_CHANGE_PASSWORD_ERROR_MESSAGE,
	data: data,
});
export const SetLoginErrorCode = (data: number) => ({
	type: actions.SET_LOGIN_ERROR_CODE,
	data: data,
});
export const SetValidateResetPasswordTokenErrorCode = (data: number) => ({
	type: actions.SET_VALIDATE_RESET_PASSWORD_TOKEN_ERROR_CODE,
	data: data,
});
export const SetResetPasswordResetErrorCode = (data: number) => ({
	type: actions.SET_RESET_PASSWORD_RESET_ERROR_CODE,
	data: data,
});

export const SetTrendUrlData = (data: IUrlTrend[], url: string) => ({
	type: actions.SET_TREND_URL_DATA,
	data: data,
	url: url,
});

export const SetOverviewData = (data: IOverviewData[]) => ({
	type: actions.SET_OVERVIEW_DATA,
	data: data,
});

export const SetFullUserState = (email: string, name: string, token: string, tokenExpiry: number) => ({
	type: actions.SET_FULL_USER_STATE,
	email,
	name,
	token,
	tokenExpiry,
});
export const SetToken = (token: string, tokenExpiry: number) => ({
	type: actions.SET_TOKEN,
	token,
	tokenExpiry,
});

export const ShowAddUrlWindowAction = (open: boolean) => ({
	type: actions.SHOW_ADD_URL_WINDOW,
	data: open,
});

export const SetGlobalMessage = (message: string) => ({
	type: actions.SET_GLOBAL_MESSAGE,
	data: message,
});

export const SetInitComplete = (status: boolean) => ({
	type: actions.SET_INIT_COMPLETE,
	data: status,
});

export const SetRefreshTokenStartAction = (status: boolean) => ({
	type: actions.SET_REFRESHING_TOKEN,
	data: status,
});

export const ResetStateAction = () => ({
	type: actions.RESET_STATE,
});

export const SetPageInternalAction = (component: string, params: Map<string, string>) => {
	return {
		type: actions.SET_PAGE,
		component: component,
		params: params,
	};
};

export const SetPageAction = (component: string, queryMap: Map<string, string>) => {
	return async (dispatch: Function, getState: Function) => {
		let params: Map<string, string> = new Map<string, string>();
		let paramString: string[] = [];

		for (let [key, value] of queryMap.entries()) {
			params.set(key, value);
			paramString.push(key + '/' + encodeURIComponent(value));
		}

		const token = getState().user.token;

		if (token == '' && !Utils.isComponentNoAuth(component)) {
			//came from a page, send to login page
			//component = Constants.LoginPage;
			component = Constants.LandingPage;
		}

		console.log('HERE');
		window.location.href =
			window.location.href.split('#')[0] + '#' + Utils.componentToUrl(component) + '/' + paramString.join('/');
		dispatch(SetPageInternalAction(component, params));
	};
};

export const GetOverviewAction = (email: string) => {
	return async (dispatch: Function, getState: Function) => {
		let state: ICombinedState = getState();
		//console.log(state);
		if (email === null) {
			email = state.user.email;
		}
		if (!Utils.isValidEmail(email)) {
			dispatch(SetPageAction(Constants.LoginPage, new Map<string, string>()));
		}
		try {
			dispatch(SetProfileUrlDataLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/overview';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ email: email }), // body data type must match "Content-Type" header
			};

			/*
			const resp: Response = await fetch(reqUrl, fetchOptions);
			const respJson: IProfileData = await resp.json();
			console.log(resp, respJson);
			*/
			const respJson: IOverviewData[] = (await (
				await quarkFetch(reqUrl, fetchOptions, state, dispatch)
			).json()) as IOverviewData[];
			dispatch(SetOverviewData(respJson));
			dispatch(SetProfileUrlDataLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetProfileUrlDataLoaded(Constants.NOT_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const GetUrlTrendAction = (url: string, period: number, interval?: number) => {
	return async (dispatch: Function, getState: Function) => {
		let state: ICombinedState = getState();
		try {
			dispatch(SetTrendUrlDataLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/urlTrend';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ url: url, duration: period, interval: interval }), // body data type must match "Content-Type" header
			};
			console.log(state, state.user, state.user.token);
			const respJson: IUrlTrend[] = (await (
				await quarkFetch(reqUrl, fetchOptions, state, dispatch)
			).json()) as IUrlTrend[];
			dispatch(SetTrendUrlData(respJson, url));
			dispatch(SetTrendUrlDataLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetTrendUrlDataLoaded(Constants.NOT_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

//get the overview and the token
export const LoginAction = (email: string, password: string) => {
	return async (dispatch: any) => {
		try {
			dispatch(SetLoginErrorCode(Constants.ERROR_CODE_NO_ERROR));
			if (!Utils.isValidEmail(email)) {
				return;
			}
			dispatch(SetLoginLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/login';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ email, password }), // body data type must match "Content-Type" header
			};
			interface Resp {
				user: ILoginResponse;
				//OverviewData: IOverviewData[];
				overview_data: {
					body: string;
				};
				errorCode: number;
				errorMessage: string;
			}

			const apiResponse: Response = await fetch(reqUrl, fetchOptions);
			console.log(apiResponse);
			const resp: Resp = (await apiResponse.json()) as Resp;
			console.log(resp);
			if (apiResponse.status != 200) {
				if (resp.errorCode !== undefined) {
					dispatch(SetLoginErrorCode(resp.errorCode));
				}
				dispatch(SetLoginLoaded(Constants.NOT_LOADED));
			} else {
				let OverviewData: IOverviewData[] = JSON.parse(resp.overview_data.body) as IOverviewData[];

				dispatch(SetOverviewData(OverviewData));
				dispatch(SetProfileUrlDataLoaded(Constants.LOADED));
				dispatch(SetFullUserState(resp.user.email, resp.user.name, resp.user.token, resp.user.token_expiry));
				dispatch(SetPageAction(Constants.Overview, new Map<string, string>()));
				dispatch(SetLoginLoaded(Constants.LOADED));
				Utils.storeLocal('email', resp.user.email);
				Utils.storeLocal('name', resp.user.name);
				Utils.storeLocal('token', resp.user.token);
				Utils.storeLocal('token_expiry', resp.user.token_expiry.toString());
			}
		} catch (e) {
			dispatch(SetLoginLoaded(Constants.NOT_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const RequestInviteAction = (email: string, name: string, subscribe: boolean) => {
	return async (dispatch: any) => {
		try {
			if (!Utils.isValidEmail(email)) {
				return;
			}
			dispatch(SetRequestInviteLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/requestInvite';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ email, name, subscribe }), // body data type must match "Content-Type" header
			};

			interface Resp {
				Success: boolean;
				Message: string;
			}
			const resp: Resp = (await (await fetch(reqUrl, fetchOptions)).json()) as Resp;
			if (!resp.Success) {
				dispatch(SetRequestInviteLoaded(Constants.NOT_LOADED));
			}
			dispatch(SetRequestInviteLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetRequestInviteLoaded(Constants.NOT_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const RegisterUserAction = (email: string, password: string, name: string, subscribe: boolean) => {
	return async (dispatch: any) => {
		try {
			if (!Utils.isValidEmail(email)) {
				return;
			}
			dispatch(SetRegisterUserLoaded(Constants.LOADING, Constants.ERROR_CODE_NO_ERROR));
			const reqUrl: string = Constants.BASE_URL + '/register';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ email, password, name, subscribe }), // body data type must match "Content-Type" header
			};

			interface Resp {
				success: boolean;
				errorMessage: string;
				errorCode: number;
			}
			const resp: Resp = (await (await fetch(reqUrl, fetchOptions)).json()) as Resp;
			if (!resp.success) {
				dispatch(SetRegisterUserLoaded(Constants.FAILED_LOADED, resp.errorCode));
				return;
			}
			dispatch(SetRegisterUserLoaded(Constants.LOADED, Constants.ERROR_CODE_NO_ERROR));
		} catch (e) {
			dispatch(SetRegisterUserLoaded(Constants.FAILED_LOADED, Constants.ERROR_CODE_EXCEPTION));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const ResetPasswordAction = (email: string) => {
	return async (dispatch: any) => {
		try {
			if (!Utils.isValidEmail(email)) {
				return;
			}
			dispatch(SetRequestPasswordLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/resetPassword';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ email }), // body data type must match "Content-Type" header
			};

			interface Resp {
				Success: boolean;
				Message: string;
			}
			const resp: Resp = (await (await fetch(reqUrl, fetchOptions)).json()) as Resp;
			if (!resp.Success) {
				dispatch(SetRequestPasswordLoaded(Constants.FAILED_LOADED));
			}
			dispatch(SetRequestPasswordLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetRequestPasswordLoaded(Constants.FAILED_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const AddUrlAction = (url: string, successCode: number, targetResponse: number) => {
	return async (dispatch: Function, getState: Function) => {
		if (url == '') {
			return;
		}
		successCode = Number(successCode);
		if (isNaN(successCode)) {
			//console.log("s");
			return;
		}
		targetResponse = Number(targetResponse);
		if (isNaN(targetResponse)) {
			return;
		}
		const state: ICombinedState = getState();
		try {
			dispatch(SetAddUrlLoaded(Constants.LOADING, url));
			const reqUrl: string = Constants.BASE_URL + '/addUrl';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ url, successCode, targetResponse }), // body data type must match "Content-Type" header
			};

			interface Resp {
				success: boolean;
				errorCode?: number;
				errorMessage?: string;
			}
			const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;
			console.log(AddUrlAction, resp);
			if (resp.success) {
				dispatch(SetAddUrlLoaded(Constants.LOADED, url));
			} else if (resp.errorCode !== undefined && resp.errorCode === Constants.ERROR_CODE_ACCOUNT_LIMIT_EXCEEDED) {
				//show error upgrade popup with message account limit exceeded
			} else {
				dispatch(SetAddUrlLoaded(Constants.NOT_LOADED, url));
			}
		} catch (e) {
			dispatch(SetAddUrlLoaded(Constants.NOT_LOADED, url));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const RemoveUrlAction = (url: string) => {
	return async (dispatch: Function, getState: Function) => {
		const state: ICombinedState = getState();
		try {
			dispatch(SetRemoveUrlLoaded(Constants.LOADING, url));
			const reqUrl: string = Constants.BASE_URL + '/removeUrl';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ url }), // body data type must match "Content-Type" header
			};

			interface Resp {
				success: boolean;
				errorCode?: number;
				errorMessage?: string;
			}
			const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;
			if (resp.success) {
				dispatch(SetRemoveUrlLoaded(Constants.LOADED, url));
			} else {
				dispatch(SetRemoveUrlLoaded(Constants.NOT_LOADED, url));
			}
		} catch (e) {
			dispatch(SetRemoveUrlLoaded(Constants.NOT_LOADED, url));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const InitAction = () => {
	return async (dispatch: Function, getState: Function) => {
		//current URL

		const state: ICombinedState = getState();
		if (state.ui.initComplete) {
			return;
		}
		const parsedUrl: IQUrl = Utils.urlToComponentAndProps();
		console.log('parsedUrl ', parsedUrl);

		if (Utils.isComponentNoAuth(parsedUrl.component)) {
			console.log('dispatching 1');
			dispatch(SetPageAction(parsedUrl.component, parsedUrl.queryMap));
			dispatch(SetInitComplete(true));
			return;
		}

		const token: string = Utils.getLocal('token');
		const email: string = Utils.getLocal('email');
		const name: string = Utils.getLocal('name');
		const tokenExpiry: number = Number(Utils.getLocal('token_expiry'));

		//bad values, return
		if (!Utils.isTokenWellFormed(token, tokenExpiry)) {
			//send to /login
			console.log('dispatching 2');
			dispatch(SetPageAction(Constants.LandingPage, new Map<string, string>()));
			dispatch(SetInitComplete(true));
			return;
		}

		//if expired refresh
		if (Utils.isTokenExpired(token, tokenExpiry)) {
			//refresh token and redirect to /login if 401 else take to page
			dispatch(RefreshTokenAction(parsedUrl));
			//redirect to home
		} else {
			//if here, the token is good
			//set state
			dispatch(SetFullUserState(email, name, token, tokenExpiry));
			if (parsedUrl.component == Constants.LoginPage) {
				parsedUrl.component = Constants.Overview;
			}
			//set page action
			dispatch(SetPageAction(parsedUrl.component, parsedUrl.queryMap));
		}
		dispatch(SetInitComplete(true));
	};
};

//parsedUrl can be null
export const RefreshTokenAction = (parsedUrl: IQUrl) => {
	return async (dispatch: Function, getState: Function) => {
		const state: ICombinedState = getState();

		dispatch(SetRefreshTokenStartAction(true));
		const token: string = Utils.getLocal('token');
		const email: string = Utils.getLocal('email');
		const name: string = Utils.getLocal('name');
		const tokenExpiry: number = Number(Utils.getLocal('token_expiry'));
		let redirectToLogin: boolean = true;

		//if email is not empty and token is well formed
		if (Utils.isTokenWellFormed(token, tokenExpiry) && email != '') {
			const reqUrl: string = Constants.BASE_URL + '/refreshToken';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ token }), // body data type must match "Content-Type" header
			};

			interface Resp {
				email: string;
				token: string;
				token_expiry: number;
			}
			try {
				const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;
				//check if the token is valid and redirect to /home
				if (email === resp.email && resp.token !== undefined && resp.token_expiry !== undefined) {
					//save data to local
					Utils.storeLocal('token', resp.token);
					Utils.storeLocal('token_expiry', resp.token_expiry.toString());
					//save data to user state
					dispatch(SetFullUserState(email, name, resp.token, resp.token_expiry));
					//redirect to parsed url
					redirectToLogin = false;
					if (parsedUrl.component == Constants.LoginPage || !Utils.isValidComponent(parsedUrl.component)) {
						parsedUrl.component = Constants.Overview;
						parsedUrl.queryMap = new Map<string, string>();
					}
					dispatch(SetPageAction(parsedUrl.component, parsedUrl.queryMap));
				}
			} catch (e) {
				//401
				//redirecting handled centrally for 401
				redirectToLogin = false;
				console.log(e);
			}
		}
		//set to login if cant refresh
		if (redirectToLogin) {
			dispatch(SetPageAction(Constants.LoginPage, new Map<string, string>()));
		}
		dispatch(SetRefreshTokenStartAction(false));
	};
};

//parsedUrl can be null
export const Logout = () => {
	return async (dispatch: Function, getState: Function) => {
		Utils.clearAllLocals();
		dispatch(ResetStateAction());
		//set to true so that laoding bars dont happen
		dispatch(SetInitComplete(true));
	};
};

//parsedUrl can be null
export const ChangePasswordAction = (oldPass: string, newPass: string) => {
	return async (dispatch: Function, getState: Function) => {
		console.log(oldPass, newPass);
		const state: ICombinedState = getState();
		try {
			dispatch(SetChangePasswordLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/changePassword';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ oldPass, newPass }), // body data type must match "Content-Type" header
			};

			interface Resp {
				email: string;
				token: string;
				token_expiry: number;
				errorCode: number;
				errorMessage: string;
			}
			const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;

			if (resp.errorCode !== undefined) {
				dispatch(SetChangePasswordErrorMessage(resp.errorMessage));
			} else if (resp.token !== undefined && resp.token_expiry !== undefined) {
				//save data to local
				Utils.storeLocal('token', resp.token);
				Utils.storeLocal('token_expiry', resp.token_expiry.toString());
				//save data to user state
				dispatch(SetToken(resp.token, resp.token_expiry));
				dispatch(SetChangePasswordSuccessMessage('Password Changed!'));
				setTimeout(() => {
					dispatch(SetChangePasswordSuccessMessage(''));
				}, 10000);
			}

			dispatch(SetChangePasswordLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetChangePasswordLoaded(Constants.NOT_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const ValidateResetPasswordTokenAction = (token: string) => {
	return async (dispatch: Function, getState: Function) => {
		const state: ICombinedState = getState();
		try {
			dispatch(SetValidateResetPasswordTokenLoaded(Constants.LOADING));
			dispatch(SetRequestPasswordFormTokenValidity(false));
			const reqUrl: string = Constants.BASE_URL + '/validateResetPasswordToken';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ token }), // body data type must match "Content-Type" header
			};

			interface Resp {
				success: boolean;
				errorCode: number;
				errorMessage: string;
			}

			const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;

			dispatch(SetRequestPasswordFormTokenValidity(resp.success));
			if (resp.success !== true) {
				dispatch(SetValidateResetPasswordTokenErrorCode(resp.errorCode));
			}
			console.log('ValidateResetPasswordTokenAction', resp);

			dispatch(SetValidateResetPasswordTokenLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetValidateResetPasswordTokenLoaded(Constants.FAILED_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};

export const ResetPasswordResetAction = (password: string, token: string) => {
	return async (dispatch: Function, getState: Function) => {
		const state: ICombinedState = getState();
		try {
			dispatch(SetResetPasswordRequestLoaded(Constants.LOADING));
			const reqUrl: string = Constants.BASE_URL + '/resetPasswordReset';
			const fetchOptions: any = {
				method: 'POST', // *GET, POST, PUT, DELETE, etc.
				cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
				body: JSON.stringify({ password, token }), // body data type must match "Content-Type" header
			};

			interface Resp {
				success: boolean;
				errorCode: number;
				errorMessage: string;
			}

			const resp: Resp = (await (await quarkFetch(reqUrl, fetchOptions, state, dispatch)).json()) as Resp;

			console.log('ResetPasswordResetAction', resp);
			if (resp.success !== true) {
				dispatch(SetResetPasswordResetErrorCode(resp.errorCode));
			} else {
				dispatch(SetResetPasswordResetErrorCode(Constants.ERROR_CODE_NO_ERROR));
			}
			dispatch(SetResetPasswordRequestLoaded(Constants.LOADED));
		} catch (e) {
			dispatch(SetResetPasswordResetErrorCode(e.getMessage()));
			dispatch(SetResetPasswordRequestLoaded(Constants.FAILED_LOADED));
			console.error(`Caught exception ${e}`);
		}
	};
};
