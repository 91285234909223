import * as React from 'react';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import {
	ResetPasswordResetAction,
	SetPageAction,
	ValidateResetPasswordTokenAction,
	SetValidateResetPasswordTokenErrorCode,
	SetValidateResetPasswordTokenLoaded,
} from '../actions';
import ICombinedState from '../reducers/interfaces/ICombinedState';
import LoadingBars from '../components/LoadingBars';
import Password from '../components/Password';

interface IResetPasswordFormComponentStateProps {
	resetPasswordResetErrorCode: number;
	validateResetPasswordTokenErrorCode: number;
	tokenValidityLoading: string;
	resetpasswordRequestLoading: string;
	queryParams: Map<string, string>;
	isTokenValid: boolean;
}
interface IResetPasswordFormComponentOwnProps {}
interface IResetPasswordFormComponentDispatchProps {
	ResetPasswordReset: Function;
	SetPage: Function;
	ValidateResetPasswordToken: Function;
	SetValidateResetPasswordTokenLoaded: Function;
	SetValidateResetPasswordTokenErrorCode: Function;
}

type IResetPasswordFormComponentProps = IResetPasswordFormComponentOwnProps &
	IResetPasswordFormComponentDispatchProps &
	IResetPasswordFormComponentStateProps;

interface IResetPasswordFormComponentState {
	password: string;
	errorMessage: string;
}

class ResetPasswordFormComponent extends React.Component<
	IResetPasswordFormComponentProps,
	IResetPasswordFormComponentState
> {
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: IResetPasswordFormComponentProps) {
		super(props);
		this.state = {
			password: '',
			errorMessage: '',
		};
		this.passwordField = React.createRef();
	}

	private getToken(): string {
		const token: string = this.props.queryParams.get('f');
		if (token === null || token === undefined) {
			//bad token request password reset again
			return null;
		}
		return token;
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
		//check if token is valid.
		const token: string = this.getToken();
		if (token !== null || token === undefined) {
			this.props.ValidateResetPasswordToken(token);
		} else {
			//todo display bad link.
			this.props.SetValidateResetPasswordTokenErrorCode(Constants.ERROR_CODE_FORGOT_PASSWORD_MISSING_TOKEN);
			this.props.SetValidateResetPasswordTokenLoaded(Constants.FAILED_LOADED);
		}
	}

	private getMessageFromErrorCode(errorCode: number): string {
		console.log('getMessageFromErrorCode', errorCode);
		switch (errorCode) {
			case Constants.ERROR_CODE_FORGOT_PASSWORD_MISSING_TOKEN:
			case Constants.ERROR_CODE_FORGOT_PASSWORD_INVALID_TOKEN:
				return 'Somehting went wrong processing this request. Please request another link.';
			case Constants.ERROR_CODE_NO_ERROR:
				return '';
			default:
				return 'Somthing went wrong. Please try again later. If the problem does not resolve, please request a new link.';
		}
	}

	private resetPassword() {
		if (
			this.props.tokenValidityLoading === Constants.LOADING ||
			this.props.tokenValidityLoading === Constants.FAILED_LOADED
		) {
			return;
		}
		let password: string = this.state.password;
		if (password === '') {
			//check DOM
			password = this.passwordField.current.value;
		}
		const token = this.getToken();
		this.props.ResetPasswordReset(password, token);
	}

	private showLogin() {
		this.props.SetPage(Constants.LoginPage, new Map<string, string>());
	}

	//generic
	private editText(val: string, e: Event) {
		let change: any = {};
		change[val] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign(this.state, change, { errorMessage: '' }));
	}

	//checkbox
	private onCheck(e: Event) {
		let subscribe: boolean = (e.currentTarget as HTMLInputElement).checked;
		this.setState(Object.assign(this.state, { subscribe: subscribe }));
	}

	private renderInvalidToken(): React.ReactNode {
		return (
			<div>
				<p className="text-center">
					{this.getMessageFromErrorCode(this.props.validateResetPasswordTokenErrorCode)}
				</p>
			</div>
		);
	}

	private renderSuccessMessage(): React.ReactNode {
		return (
			<div>
				<p className="text-center">Password Change successfully! Please use your new password</p>
			</div>
		);
	}

	private renderChangePassword(): React.ReactNode {
		return (
			<form
				className="p-5 w-full md:w-1/2 mx-auto"
				onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
					e.preventDefault();
					this.resetPassword();
				}}
			>
				<p className="mx-auto">Enter your new password</p>
				<Password
					text={this.state.password}
					onKeyUp={this.editText.bind(this, 'password')}
					fieldRef={this.passwordField}
				/>
				<p className="text-red-500 text-xs italic -my-2">{this.state.errorMessage}&nbsp;</p>
				<p className="text-red-500 text-xs italic -my-2">
					{this.getMessageFromErrorCode(this.props.validateResetPasswordTokenErrorCode)}&nbsp;
				</p>
				<p className="text-red-500 text-xs italic -my-2">
					{this.props.resetPasswordResetErrorCode !== Constants.ERROR_CODE_NO_ERROR
						? 'There was an error, please try again.'
						: null}
					&nbsp;
				</p>
				<div>
					<button
						type="submit"
						className="btn btn-blue-outline my-2 w-full"
						disabled={this.props.tokenValidityLoading === Constants.LOADING}
					>
						Reset Password
					</button>
				</div>
			</form>
		);
	}

	public render(): React.ReactNode {
		if (
			this.props.tokenValidityLoading !== Constants.LOADED &&
			this.props.tokenValidityLoading !== Constants.FAILED_LOADED
		) {
			return <LoadingBars />;
		}

		//request made and is no error code
		const showSuccessMessage =
			this.props.resetpasswordRequestLoading == Constants.LOADED &&
			this.props.resetPasswordResetErrorCode == Constants.ERROR_CODE_NO_ERROR;
		console.log(
			'showSuccessMessage',
			showSuccessMessage,
			this.props.resetpasswordRequestLoading,
			this.props.resetPasswordResetErrorCode,
		);

		return (
			<div>
				{this.props.isTokenValid
					? showSuccessMessage
						? this.renderSuccessMessage()
						: this.renderChangePassword()
					: this.renderInvalidToken()}
				<div className="w-full text-center my-2">
					<div className="">
						<button onClick={this.showLogin.bind(this)} className="btn btn-blue-link">
							Login
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (
	state: ICombinedState,
	ownProps: IResetPasswordFormComponentStateProps,
): IResetPasswordFormComponentStateProps => {
	return {
		resetpasswordRequestLoading: state.ui.resetpasswordRequestLoading,
		resetPasswordResetErrorCode: state.ui.resetPasswordResetErrorCode,
		validateResetPasswordTokenErrorCode: state.ui.validateResetPasswordTokenErrorCode,
		tokenValidityLoading: state.ui.validateResetPasswordTokenLoading,
		queryParams: state.ui.params,
		isTokenValid: state.ui.resetpasswordFormTokenValidity,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: IResetPasswordFormComponentDispatchProps) => {
	return {
		ResetPasswordReset: (password: string, token: string) => {
			dispatch(ResetPasswordResetAction(password, token));
		},
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		ValidateResetPasswordToken: (token: string) => {
			dispatch(ValidateResetPasswordTokenAction(token));
		},
		SetValidateResetPasswordTokenErrorCode: (message_code: number) => {
			dispatch(SetValidateResetPasswordTokenErrorCode(message_code));
		},
		SetValidateResetPasswordTokenLoaded: (loaded_status: string) => {
			dispatch(SetValidateResetPasswordTokenLoaded(loaded_status));
		},
	};
};

export default connect<IResetPasswordFormComponentStateProps, IResetPasswordFormComponentDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ResetPasswordFormComponent);
