import * as React from 'react';
import { LineChart, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import IUrlTrend from '../../interfaces/IUrlTrend';
import * as Utils from '../../utils';

interface IGraphOwnState {}
interface IGraphOwnProps {
    graphData: IUrlTrend[];
}

interface ILabel {
    payload: any;
    x: number;
    y: number;
    width: number;
    height: number;
    value: number;
}

export default class Graph extends React.Component<IGraphOwnProps, IGraphOwnState> {
    //static jsfiddleUrl = 'https://jsfiddle.net/alidingling/9kd8rssL/';
    private renderCustomBarLabel({ payload, x, y, width, height, value }: ILabel): React.ReactNode {
        return (
            <text x={x + width / 2} y={y} fill="#666" textAnchor="middle" dy={-6}>{`${Math.ceil(
                value / 1000000,
            )}ms`}</text>
        );
    }
    private renderCustomTooltip({
        active,
        payload,
        label,
    }: {
        active: boolean;
        payload: any;
        label: number;
    }): React.ReactNode {
        //console.log("renderCustomTooltip", active, payload, label);
        const p: IUrlTrend = this.props.graphData[label];
        //sometimes this is undefined
        if(p===undefined) {
            //console.log("undefined_p",this.props.graphData, label)
            return null;
        }
        if (active) {
            return (
                <div className="bg-white p-1">
                    <p className="label">{`${Math.ceil(p.Totaltime / 1000000)}ms`}</p>
                    <p className="intro">{`${Utils.getDateString(p.Time)}`}</p>
                    <p className="desc">{`Status: ${p.StatusCode}`}</p>
                </div>
            );
        }
        return null;
    }

    private renderCustomizedTick(a: number): string {
        //        console.log('jhfbvhsvkj', a);
        //return <p>{a.payload.value / 1000 / 1000 + 'ms'}</p>;
        //return a.payload.value / 1000 / 1000 + 'ms';
        return a / 1000 / 1000 + 'ms';
    }

    public render(): React.ReactNode {
        console.log('this.props.graphData', this.props.graphData);
        //
        //<Bar dataKey="Totaltime" fill="#8884d8" label={this.renderCustomBarLabel.bind(this)} />;
        if (this.props.graphData.length == 0) {
            return null;
        }
        return (
            <div className="mx-5 h-64">
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={this.props.graphData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <YAxis tickFormatter={this.renderCustomizedTick.bind(this)} />
                        <Tooltip content={this.renderCustomTooltip.bind(this)} />
                        <Legend />
                        <Line type="monotone" dataKey="Totaltime" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        );
    }
}
