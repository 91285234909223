import * as React from 'react';
import * as CSS from 'csstype';
import * as Constants from '../../constants';
import * as Utils from '../../utils';

interface HeaderOwnProps {
	LoggedIn: boolean;
	SetPage: Function;
	Logout: Function;
}
interface HeaderOwnState {
	openMenu: boolean;
}

export default class Header extends React.Component<HeaderOwnProps, HeaderOwnState> {
	constructor(props: HeaderOwnProps) {
		super(props);
		this.state = {
			openMenu: false,
		};
	}

	private toggleMenu(e: Event) {
		console.log(this.state);
		this.setState({ openMenu: !this.state.openMenu });
	}

	private renderMenuItem(text: string) {
		if (text == '') {
			return null;
		}
		return (
			<li className="cursor-pointer m-2" onClick={this.menuClicked.bind(this, text)}>
				{text}
			</li>
		);
	}

	private menuClicked(linkText: string, event: MouseEvent) {
		switch (linkText) {
			case 'Home':
				this.props.SetPage(Constants.Overview, new Map<string, string>());
				break;
			case 'About':
				this.props.SetPage(Constants.AboutPage, new Map<string, string>());
				break;
			case 'Pricing':
				this.props.SetPage(Constants.PricingPage, new Map<string, string>());
				break;
			case 'Settings':
				this.props.SetPage(Constants.SettingsPage, new Map<string, string>());
				break;
			case 'Logout':
				this.props.Logout();
				this.props.SetPage(Constants.LoginPage, new Map<string, string>());
				break;
			case 'Sign In':
				this.props.SetPage(Constants.LoginPage, new Map<string, string>());
				break;

			default:
				// code...
				break;
		}
		//close menu
		this.setState({ openMenu: false });
	}

	private menuClickedWithToggle(linkText: string, event: MouseEvent) {
		this.menuClicked(linkText, event);
	}

	private renderMenuMobile(userAction: string[]): React.ReactNode {
		return (
			<div className="w-full mt-20 flex justify-end absolute top-0 left-0">
				<ul className="menu-nav" style={{ display: this.state.openMenu ? 'flex' : 'none', zIndex: 1 }}>
					{userAction.map((val: string) => {
						return this.renderMenuItem(val);
					})}
				</ul>
			</div>
		);
	}

	private renderMenuLarge(userAction: string[]): React.ReactNode {
		return (
			<ul className="menu-nav nav-large">
				{userAction.map((val: string) => {
					return this.renderMenuItem(val);
				})}
			</ul>
		);
	}

	private renderMenu(): React.ReactNode {
		const menuItems: string[] = [];
		if (!this.props.LoggedIn) {
			menuItems.push('About');
			menuItems.push('Pricing');
		}
		if (this.props.LoggedIn) {
			menuItems.push('Home');
			menuItems.push('Settings');
			menuItems.push('Logout');
		} else {
			menuItems.push('Sign In');
		}
		//const userAction = this.props.LoggedIn ? 'Logout' : '';
		const iconImage = this.state.openMenu
			? 'mfg-labs-iconset_2014-07-29_cross_mark_256_0_007dff_none.png'
			: 'typicons_2-0-7_th-menu_256_0_007dff_none.png';
		return (
			<div>
				<a className="block cursor-pointer sm:hidden">
					<img className="w-6" onClick={this.toggleMenu.bind(this)} src={Utils.getAssetUrl(iconImage)} />
				</a>
				{this.renderMenuLarge(menuItems)}
				{this.renderMenuMobile(menuItems)}
			</div>
		);
	}
	public render(): React.ReactNode {
		const headerStyle: CSS.Properties = {
			//backgroundColor: '#77a6f7',
			/*backgroundColor: '#fff',*/
			color: '#333',
		};
		return (
			<div className="header flex justify-between items-center p-8 bg-gray-100" style={headerStyle}>
				<h1 style={{ margin: '0px' }}>ROBOPOLLER</h1>
				{this.renderMenu()}
			</div>
		);
	}
}
