import * as React from 'react';
import * as Constants from '../../constants';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

import Popup from './Popup';

//import * as styles from './Overview.css';

interface IUpgradePlanPopupOwnState {
	//isOpen: boolean;
}
interface IUpgradePlanPopupOwnProps {
	isOpen: boolean;
	onClose: Function;
	message: string;
}

//class renders the home page for user
export default class UpgradePlanPopup extends React.Component<IUpgradePlanPopupOwnProps, IUpgradePlanPopupOwnState> {
	constructor(props: IUpgradePlanPopupOwnProps) {
		super(props);
		//this.state = { isOpen: true };
	}
	public componentDidMount() {}

	private onClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		this.props.onClose(e);
	}

	private getComponentForPopup(): React.ReactElement {
		return (
			<div className="p-4">
				<p>{this.props.message}</p>
			</div>
		);
	}

	public render(): React.ReactNode {
		if (!this.props.isOpen) {
			return null;
		}
		const formItems: CSS.Properties = {
			display: 'flex',
			flexDirection: 'row',
			margin: '1em',
		};

		return (
			<Popup
				IsOpen={this.props.isOpen}
				component={this.getComponentForPopup()}
				onClose={this.onClose.bind(this)}
				actionButtonText=""
				actionButtonOnClick={() => {}}
			/>
		);
	}
}
