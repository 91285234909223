import * as React from 'react';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IOverviewData from '../../interfaces/IOverviewData';
import OverviewRow from './OverviewRow';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

import Popup from '../components/Popup';

//import * as styles from './Overview.css';

interface IAddUrlOwnState {
	url: string;
	successCode: number;
	targetResponse: number;
	urlValidationMessage: string;
}
interface IAddUrlOwnProps {
	AddUrl: Function;
	ShowAddUrlWindow: Function;
	IsOpen: boolean;
}

//class renders the home page for user
export default class AddUrlPopup extends React.Component<IAddUrlOwnProps, IAddUrlOwnState> {
	constructor(props: IAddUrlOwnProps) {
		super(props);
		this.state = { url: '', successCode: 200, targetResponse: 20, urlValidationMessage: '' };
	}
	public componentDidMount() {}

	private validateUrl(url: string): string {
		const regx: RegExp = /(http|https):\/\/(.*)\.(.*)/;
		const found: RegExpExecArray = regx.exec(url);
		if (found === null) {
			return 'Error in the url. Format: http(s)://<ip or website>.';
		}
		return '';
	}

	private onTextChanged(key: string, e: Event) {
		const changed: any = {};
		changed[key] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign({}, this.state, changed));
		if (key == 'url' && this.state.urlValidationMessage !== '') {
			const message: string = this.validateUrl(changed[key]);
			this.setState(Object.assign({}, this.state, { urlValidationMessage: message }));
		}
	}
	private onOptionChanged(key: string, e: Event) {
		const changed: any = {};
		changed[key] = (e.currentTarget as HTMLSelectElement).value;
		this.setState(Object.assign({}, this.state, changed));
	}

	private onAdd(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
		const message: string = this.validateUrl(this.state.url);
		if (message != '') {
			this.setState(Object.assign({}, this.state, { urlValidationMessage: message }));
			return;
		}
		//perform the add
		this.props.AddUrl(this.state.url, this.state.successCode, this.state.targetResponse);
		this.props.ShowAddUrlWindow(false);
		//refresh the current overview for the user
	}

	private onClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
		this.props.ShowAddUrlWindow(false);
	}

	private popupComp(): React.ReactElement {
		return (
			<div className="w-4/5 mx-auto">
				<h2 className="text-center my-5">Add URL for tracking</h2>
				<div className="mb-4">
					<label
						className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
						htmlFor="url-address"
					>
						Complete URL
					</label>
					<input
						id="url-address"
						type="text"
						onKeyUp={this.onTextChanged.bind(this, 'url')}
						placeholder="https://www.logic-square.com/"
						className="textbox-outline-blue m-2 block w-full"
					/>
					<p className="text-red-500 text-xs italic -my-2">{this.state.urlValidationMessage}&nbsp;</p>
				</div>
				<div className="flex flex-wrap -mx-3 mb-6">
					<div className="w-full px-3 mb-4 xl:w-1/2">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="url-response-codes"
						>
							Response Code
						</label>
						<select
							id="url-response-codes"
							className="select-blue block w-full m-2"
							onChange={this.onOptionChanged.bind(this, 'successCode')}
						>
							<option value="200">200</option>
							<option value="404">404</option>
							<option value="401">401</option>
						</select>
					</div>
					<div className="w-full px-3 mb-4 xl:w-1/2">
						<label
							className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
							htmlFor="url-response-time"
						>
							Response Time (milliseconds)
						</label>
						<input
							id="url-response-time"
							type="text"
							onKeyUp={this.onTextChanged.bind(this, 'targetResponse')}
							placeholder="40"
							className="textbox-outline-blue m-2 block w-full"
						/>
					</div>
				</div>
			</div>
		);
	}

	public render(): React.ReactNode {
		if (!this.props.IsOpen) {
			return null;
		}

		return (
			<Popup
				actionButtonText="Add"
				actionButtonOnClick={this.onAdd.bind(this)}
				IsOpen={this.props.IsOpen}
				component={this.popupComp()}
				onClose={this.onClose.bind(this)}
			/>
		);
	}
}
