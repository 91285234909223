import * as React from 'react';
import * as Constants from '../../constants';
import * as Utils from '../../utils';
import { connect } from 'react-redux';
import { RegisterUserAction, SetPageAction, SetRegisterUserLoaded } from '../actions';
import ICombinedState from '../reducers/interfaces/ICombinedState';
import Password from '../components/Password';
import Popup from '../components/Popup';

interface ISignUpComponentStateProps {
	registerUserLoading: string;
	registerUserErrorCode: number;
}
interface ISignUpComponentOwnProps {}
interface ISignUpComponentDispatchProps {
	RegisterUser: Function;
	SetPage: Function;
	SetRegisterUserLoadedAction: Function;
}

type ISignUpComponentProps = ISignUpComponentOwnProps & ISignUpComponentDispatchProps & ISignUpComponentStateProps;

interface ISignUpComponentState {
	email: string;
	password: string;
	name: string;
	subscribe: boolean;
	signUpEmailError: string;
}

class SignUpComponent extends React.Component<ISignUpComponentProps, ISignUpComponentState> {
	private emailField: React.RefObject<HTMLInputElement>;
	private passwordField: React.RefObject<HTMLInputElement>;

	constructor(props: ISignUpComponentProps) {
		super(props);
		this.state = {
			email: '',
			password: '',
			name: '',
			subscribe: true,
			signUpEmailError: '',
		};
		this.emailField = React.createRef();
		this.passwordField = React.createRef();
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
		//reset state
		this.props.SetRegisterUserLoadedAction(Constants.NOT_LOADED, Constants.ERROR_CODE_NO_ERROR);
	}

	private signUp() {
		if (this.props.registerUserLoading === Constants.LOADING) {
			return;
		}

		let email: string = this.state.email;
		if (email === '') {
			//check DOM
			email = this.emailField.current.value;
		}
		if (!Utils.isValidEmail(email)) {
			this.setState({ signUpEmailError: 'Email not valid' });
			return;
		}

		let password: string = this.state.password;
		if (password === '') {
			//check DOM
			password = this.passwordField.current.value;
		}
		if (email == '' || password == '') {
			this.setState({ signUpEmailError: 'password or email is empty' });
			return;
		}

		this.props.RegisterUser(email, password, this.state.name, this.state.subscribe);
	}

	//generic
	private editText(val: string, e: Event) {
		let change: any = {};
		change[val] = (e.currentTarget as HTMLInputElement).value;
		this.setState(Object.assign(this.state, change, { loginEmailError: '', signUpEmailError: '' }));
	}

	//checkbox
	private onCheck(e: Event) {
		let subscribe: boolean = (e.currentTarget as HTMLInputElement).checked;
		this.setState(Object.assign(this.state, { subscribe: subscribe }));
	}

	private showLoginPage() {
		console.log('show login');
		this.props.SetPage(Constants.LoginPage, new Map<string, string>());
	}

	private getPopupMessage(): string {
		if (this.props.registerUserErrorCode == Constants.ERROR_CODE_USER_EXISTS) {
			return 'Account with this email already exists. Try signing in.';
		}
		return 'Account Created! Sign in to proceed.';
	}

	public render(): React.ReactNode {
		return (
			<div className="p-5 w-full md:w-1/2 mx-auto">
				<form
					onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
						e.preventDefault();
						this.signUp();
					}}
				>
					<p>Sign Up</p>
					<input
						className="textbox-outline-blue my-2 w-full"
						onKeyUp={this.editText.bind(this, 'email')}
						type="email"
						name="signUpEmail"
						placeholder="Email"
						ref={this.emailField}
					/>
					<p className="text-red-500 text-xs italic -my-2">{this.state.signUpEmailError}&nbsp;</p>
					<input
						className="textbox-outline-blue my-2 w-full"
						onKeyUp={this.editText.bind(this, 'name')}
						type="textfield"
						name="name"
						placeholder="Full Name (Optional)"
					/>
					<div>
						<Password
							text={this.state.password}
							onKeyUp={this.editText.bind(this, 'password')}
							fieldRef={this.passwordField}
						/>
					</div>
					<div>
						<input
							onChange={this.onCheck.bind(this)}
							type="checkbox"
							name="subscribe"
							checked={this.state.subscribe}
						/>
						<span className="text-xs ml-2">Subscribe to ROBOPOLLER newsletter</span>
					</div>
					<button
						type="submit"
						className="btn btn-blue-outline my-2 w-full"
						onClick={this.signUp.bind(this)}
						disabled={this.props.registerUserLoading === Constants.LOADING}
					>
						Create Account
					</button>
				</form>
				<div className="text-center">
					Already have an account?{' '}
					<button onClick={this.showLoginPage.bind(this)} className="btn btn-blue-link">
						Log in
					</button>
				</div>
				<Popup
					IsOpen={
						this.props.registerUserLoading === Constants.LOADED ||
						this.props.registerUserLoading === Constants.FAILED_LOADED
					}
					component={<p className="text-center p-2">{this.getPopupMessage()}</p>}
					actionButtonText=""
					actionButtonOnClick={() => {}}
					onClose={this.showLoginPage.bind(this)}
					closeButtonText="Sign In"
				/>
			</div>
		);
	}
}

const mapStateToProps = (state: ICombinedState, ownProps: ISignUpComponentStateProps): ISignUpComponentStateProps => {
	return {
		registerUserLoading: state.ui.registerUserLoading,
		registerUserErrorCode: state.ui.registerUserErrorCode,
	};
};

const mapDispatchToProps = (dispatch: any, ownProps: ISignUpComponentDispatchProps) => {
	return {
		RegisterUser: (email: string, password: string, name: string, subscribe: boolean) => {
			dispatch(RegisterUserAction(email, password, name, subscribe));
		},
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
		SetRegisterUserLoadedAction: (data: string, errorCode: number) => {
			dispatch(SetRegisterUserLoaded(data, errorCode));
		},
	};
};

export default connect<ISignUpComponentStateProps, ISignUpComponentDispatchProps>(
	mapStateToProps,
	mapDispatchToProps,
)(SignUpComponent);
