import * as React from 'react';
import * as Constants from '../../constants';
import { connect } from 'react-redux';
import * as Utils from '../../utils';
import { SetPageAction } from '../actions';

interface IProps {
	SetPage?: Function;
}

class PricingContainer extends React.Component<IProps, any> {
	constructor(props: IProps) {
		super(props);
	}
	public componentDidMount() {
		//this.props.GetOverviewData('global');
	}

	private onSignupClick() {
		this.props.SetPage(Constants.SignUpPage, new Map<string, string>());
	}

	public render(): React.ReactNode {
		const headingText: string = 'lg:text-5xl md:text-3xl text-2xl my-4';
		const subheadingText: string = 'text-base m-2';
		const imageClasses: string = 'm-auto object-cover md:max-w-md ';
		const infoCard: string = 'my-10 pb-4 border-2 border-slate-200 rounded md:w-1/4 w-5/6 mx-auto';
		const infoCardHeading: string = 'pt-3 border-slate-200 text-base';
		const infoCardPricing: string = 'py-3 border-b-2 border-slate-200 text-xl';
		const infoCardContent: string = 'py-4  text-base  px-5';
		const infoCardSubText: string = 'h-12 flex items-center justify-center';
		const infoCardList: string = 'text-left list-inside list-disc py-5  inline-block mx-auto';
		return (
			<div className="about text-center text-2xl mb-24">
				<div className="text-center mb-12">
					<p className={headingText}>Pricing</p>
				</div>
				<div className="flex md:justify-around justify-center flex-col md:flex-row">
					<div className={infoCard}>
						<p className={infoCardHeading}>Free Forever</p>
						<p className={infoCardPricing}>$0/mo</p>
						<div className={infoCardContent}>
							<p className={infoCardSubText}>Best for getting started</p>
							<ul className={infoCardList}>
								<li>10 URLS</li>
								<li>once a day</li>
							</ul>
						</div>
					</div>
					<div className={infoCard}>
						<p className={infoCardHeading + ' text-green-700'}>Personal (Coming Soon)</p>
						<p className={infoCardPricing}>$5/mo</p>
						<div className={infoCardContent}>
							<p className={infoCardSubText}>Best for personal use</p>
							<ul className={infoCardList}>
								<li>25 URLS</li>
								<li>hourly checks</li>
								<li>email notifications</li>
								<li>SMS alerts</li>
							</ul>
						</div>
					</div>
					<div className={infoCard}>
						<p className={infoCardHeading + ' text-blue-700'}>Professional (Coming Soon)</p>
						<p className={infoCardPricing}>$25/mo</p>
						<div className={infoCardContent}>
							<p className={infoCardSubText}>When you are serious about monitoring</p>
							<ul className={infoCardList}>
								<li>1000 URLS</li>
								<li>check every minute</li>
								<li>email notification</li>
								<li>SMS alerts</li>
								<li>Webhooks</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state: any, ownProps: IProps): IProps => {
	return {};
};

const mapDispatchToProps = (dispatch: any, ownProps: IProps) => {
	return {
		SetPage: (component: string, params: Map<string, string>) => {
			dispatch(SetPageAction(component, params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingContainer);
