import * as React from 'react';
import * as Constants from '../../constants';
import Header from '../components/Header';
import Footer from '../components/Footer';
import IOverviewData from '../../interfaces/IOverviewData';
import OverviewRow from './OverviewRow';
import AddUrl from './AddUrl';
import AddUrlPopup from './AddUrlPopup';
import LoadingBars from '../components/LoadingBars';

import * as CSS from 'csstype';
import * as Utils from '../../utils';

interface IOverviewOwnState {}
interface IOverviewOwnProps {
	GetOverviewData: Function;
	SetPage: Function;
	AddUrl: Function;
	overviewData: IOverviewData[];
	ShowAddUrlWindow: Function;
	addUrlWindowState: boolean;
	RemoveUrl: Function;
	ProfileDataLoaded: string;
	urlCount: number;
	urlLimit: number;
}

//class renders the home page for user
export default class Overview extends React.Component<IOverviewOwnProps, IOverviewOwnState> {
	public componentDidMount() {}

	public render(): React.ReactNode {
		if (this.props.ProfileDataLoaded !== Constants.LOADED) {
			return <LoadingBars />;
		}
		return (
			<div className="max-w-lg m-auto">
				<AddUrl
					AddUrl={this.props.AddUrl}
					ShowAddUrlWindow={this.props.ShowAddUrlWindow}
					GetOverviewData={this.props.GetOverviewData}
					urlCount={this.props.urlCount}
					urlLimit={this.props.urlLimit}
				/>
				{this.props.overviewData.length == 0 ? <p>Add a url to see monitoring data</p> : null}
				{this.props.overviewData.map((v: IOverviewData, index: number) => {
					return (
						<OverviewRow
							key={index}
							UrlData={v}
							SetPage={this.props.SetPage}
							RemoveUrl={this.props.RemoveUrl}
						/>
					);
				})}
				<AddUrlPopup
					IsOpen={this.props.addUrlWindowState}
					AddUrl={this.props.AddUrl}
					ShowAddUrlWindow={this.props.ShowAddUrlWindow}
				/>
			</div>
		);
	}
}
